<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <label class="input-label">{{ label }}</label>
    <Multiselect
      v-model="values"
      track-by="value"
      :options="options"
      :multiple="false"
      :close-on-select="false"
      label="label"
      data-cy="filters__multi-selector"
      placeholder="Select"
      :data-cy-key="model_keys[0]"
      @input="handle_input"
    />
  </div>
</template>


<script>
import { Multiselect } from 'vue-multiselect';

/*
 *
 * The MultiSelector component wraps vue-multiselect and populates with values from an endpoint
 *
 */
export default {
  name: 'BooleanSelector',
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    model_keys: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [{
        label: "Yes",
        value: true
      },{
        label: "No",
        value: false
      }],
      values: []
    };
  },
  computed: {
    current_prop_value() {
      return this.model[this.model_keys[0]];
    }
  },
  watch: {
    current_prop_value() {
      this.values = this.model[this.model_keys[0]] ? (this.options.filter((opt) => this.model[this.model_keys[0]].includes(opt.value))) : [];
    }
  },
  methods: {
    handle_input(o){
      if(o != null){
        this.model[this.model_keys[0]] = [o.value];
      }else{
        this.model[this.model_keys[0]] = [];
      }
    },
  }
};

</script>
