<template>
  <Modal :show="!!text_snippet" size="large" @close="reset">
    <template #header>
      <div class="modal__content modal__content--header">
        <h5 v-if="is_new_snippet" class="modal__heading">
          New Snippet
        </h5>
        <h5 v-else class="modal__heading">
          Edit Snippet Text: {{ text_snippet.key }}
        </h5>
      </div>
    </template>
    <template #body>
      <div class="modal__content modal__content--body">
        <div class="row align-middle">
          <template v-if="is_new_snippet">
            <div class="column small-6">
              <label>
                Key:
                <input v-model="text_snippet.key" type="text" class="input">
              </label>
            </div>
            <div class="column small-6">
              <label>
                Type:
                <select v-model="text_snippet.editor_type">
                  <option value="text">Text</option>
                  <option value="wysiwyg">WYSIWYG</option>
                </select>
              </label>
            </div>
          </template>
          <div class="column small-12">
            <label v-if="is_new_snippet">
              Content
            </label>
            <input
              v-if="text_snippet.editor_type === 'text'"
              v-model="text_snippet.content"
              class="input"
              type="text"
            >
            <QuillEditor
              v-if="text_snippet.editor_type === 'wysiwyg'"
              v-model="text_snippet.content"
              :options="vdata.default_quill_options"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal__content modal__content--footer">
        <template>
          <div class="row">
            <div class="column small-12 medium-4">
              <button class="button button--secondary expanded" @click="reset">
                Cancel
              </button>
            </div>
            <div class="column small-12 medium-4">
              <button class="button expanded" @click="save">
                Save
              </button>
            </div>
            <div v-if="!is_new_snippet" class="column medium-4">
              <button class="button expanded button--warning" @click="delete_snippet">
                Delete
              </button>
            </div>
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
import { default as Modal } from 'shared/modal.vue';
import { quillEditor as QuillEditor } from 'vue-quill-editor';
import { EventBus } from 'shared/vue-configured';

export default {
  name: "TextSnippetEditModal",
  components: {
    Modal,
    QuillEditor
  },
  props: {
    vdata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      text_snippet_id: false,
      text_snippet:    false
    }
  },
  computed: {
    is_new_snippet(){
      return(this.$data.text_snippet_id === 'new');
    }
  },
  watch: {
    "vdata.ui_state.text_snippet"(newval) {
      if(newval){
        this.$data.text_snippet_id = newval;
        if(newval === 'new'){
          this.new_text_snippet();
        }else{
          this.get_content();
        }
      }else{
        this.reset;
      }
    }
  },
  methods: {
    delete_snippet(){
      if(confirm(`Delete ${this.$data.text_snippet.key}?`)){
        this.push_and_receive('DELETE')
          .then( this.reset )
      }
    },
    fetch_failure(reject, jqXHR){
      alert('There was a problem fetching data');
      console.log(jqXHR);
      return reject();
    },
    get_content() {
      this.push_and_receive('GET');
    },
    new_text_snippet(){
      this.text_snippet = {
        editor_type: "text",
        key: "",
        content: ""
      };
    },
    push_and_receive(method) {
      return new Promise((resolve, reject)=> {
        const payload = JSON.parse(JSON.stringify(this.text_snippet));
        let url;
        if(this.text_snippet_id === 'new'){
          url = `/admin/text_snippets/`;
          method = 'POST';
        }else{
          url = `/admin/text_snippets/${this.text_snippet_id}/edit`;
        }
        return $.ajax({
            url: url,
            type: method,
            data: payload,
            dataType: 'json',
            success: this.receive.bind(this, resolve, reject),
            error: this.fetch_failure.bind(this, reject)
        });
      });
    },
    receive(resolve, reject, response){
      if (response.errors && response.errors.length > 0) { // problem!
        alert(`Could not save: ${response.errors[0]}`);
        reject();
        return;
      }

      if (response.text_snippet) {
        this.text_snippet = response.text_snippet
      }

      resolve(response);
    },
    reset(){
      this.$data.text_snippet_id = false;
      this.$data.text_snippet = false;
      this.$emit('close')
      EventBus.$emit('tabular_data:save_snippet');
    },
    save() {
      this.push_and_receive('PATCH')
        .then( this.reset )
    },
  }
};

</script>

<style lang="scss" scoped>
  @import "~quill/dist/quill.snow.css";
  .modal__content {

    &--body {
      height: auto;
      overflow-x: hidden;
    }
  }
</style>
