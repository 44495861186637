<template>
  <div>
    <div class="row align-right">
      <div class="column small-12">
        <div :class="{ 'card card--large card--border-light-gray card--no-shadow card--no-radius': show_investment_closed_notice }">
          <div class="row align-middle">
            <div
              v-if="show_investment_closed_notice"
              class="column small-12 large-6"
            >
              <p class="text-smaller text-center large-text-left small-mb-10 large-mb-0">
                Your investment in this deal has already closed. You can now view your investment in <a :href="vdata.ui_defs.portfolio_path">your portfolio</a>.
              </p>
            </div>
            <div class="column" :class="[ show_investment_closed_notice ? 'small-12 large-6' : 'small-12' ]">
              <a
                v-if="show_button"
                :href="cta_href"
                class="button button--uppercase button--large button--primary button--small-expanded"
                :class="{ 'small-mb-8 medium-mb-0': !show_investment_closed_notice }"
                data-cy="deal-hero__button-invest-now"
              >
                {{ cta_text }}
              </a>

              <div
                v-if="show_dropdown"
                class="button-dropdown small-mb-4 small-mt-4"
                :class="{ 'button-dropdown--is-open': dropdown_is_open }"
                @click="toggle_dropdown"
              >
                <button class="button-dropdown__button button button--uppercase button--large button--primary" type="button">
                  {{ cta_text }}
                  <span class="button-dropdown__indicator" />
                </button>
                <div class="button-dropdown__pane">
                  <ol class="button-dropdown__list">
                    <li v-for="(offer, index) in vdata.ui_defs.active_offers" :key="index" class="button-dropdown__list-item">
                      <a :href="offer.next_step_link" class="link link--underline">
                        <span class="link__text">{{ offer.entity_name }}</span>
                        <span class="link__icon icon-chevron-right-small" />
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_new_offer_link && !hide_new_offer_link" class="row">
      <div class="column small-12">
        <div class="text-center large-text-right small-mb-14 large-mb-0">
          <a :href="vdata.ui_defs.make_offer_path" class="link link--underline link--small">
            <span class="link__text">Start a New Offer</span>
            <span class="link__icon icon-chevron-right-small" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestCTA",
  props: {
    vdata: {
      type:     Object,
      required: true,
    },
    hide_new_offer_link: {
      type:     Boolean,
      default:  false
    },
    show_investment_notice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dropdown_is_open: false
    }
  },
  computed: {
    active_offer_count() {
      return this.vdata.ui_defs.active_offers?.length || 0;
    },
    cta_href() {
      if (this.active_offer_count === 1)  {
        return this.vdata.ui_defs.active_offers[0].next_step_link;
      } else {
        return this.vdata.ui_defs.make_offer_path;
      }
    },
    cta_text() {
      if (this.active_offer_count === 1) {
        return "Continue My Offer";
      } else if (this.active_offer_count > 1 && this.countersigned_offer_count == 0) {
        return "Continue My Offers";
      } else if (this.countersigned_offer_count > 0) {
        return "Invest Again";
      } else {
        return "Invest Now";
      }
    },
    countersigned_offer_count() {
      return this.vdata.ui_defs.countersigned_offer_count;
    },
    show_button() {
      return !['Closed', 'Realized'].includes(this.vdata.deal.deal_status_human) && !this.show_dropdown;
    },
    show_dropdown() {
      return this.active_offer_count > 1 && this.countersigned_offer_count == 0;
    },
    show_investment_closed_notice() {
      return this.countersigned_offer_count > 0 && this.show_investment_notice && !['closed', 'realized'].includes(this.vdata.deal.deal_status);
    },
    show_new_offer_link () {
      return this.active_offer_count > 0 && this.countersigned_offer_count == 0;
    },
  },
  methods: {
    toggle_dropdown() {
      return this.dropdown_is_open = !this.dropdown_is_open;
    }
  }
};
</script>
