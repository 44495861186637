<template>
  <div>
    <transition name="modal">
      <div v-if="vdata.ui_state.show" class="modal">
        <div class="modal__wrapper" @click.self="close_on_click()">
          <div class="modal__container">
            <div class="modal__header">
              <button class="modal__close" @click="close()">
                <span class="modal__close-icon icon-times" />
              </button>
            </div>

            <div class="modal__body">
              <div class="modal__content modal__content--header" v-html="vdata.ui_state.html" />
              <div v-if="vdata.ui_state.loading" class="modal-loader" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
 /* eslint vue/no-v-html: "off" */

import EventBus from 'shared/event-bus';

export default
  ({
    name: "AjaxModal",
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    beforeDestroy() {
      EventBus.$off('keypress:esc', this.close);
      EventBus.$off('ajax_modal:open', this.open);
      $(document).off('click', '[data-ajax-modal]');
    },
    mounted() {
      EventBus.$on('keypress:esc', this.close);
      EventBus.$on('ajax_modal:open', this.open);
      $(document).on('click', '[data-ajax-modal]', this.handleClick);
    },
    methods: {
      close() {
        this.vdata.ui_state.show = false;
        this.vdata.ui_state.html = '';
      },
      close_on_click() {
        if (this.vdata.ui_state.close_on_click) {
          this.close();
        }
      },
      handleClick(e){
        e.preventDefault();
        const $target = $(event.target);

        let url, close_on_click = ($target.attr('data-close-on-click') === 'false' ? false : true)

        if ($target.is('a')) {
          url = $target.attr('href');
        } else {
          url = $target.attr('data-href');
        }

        if (url) {
          this.open(url, close_on_click)
        } else {
          console.error('AjaxModal:','href or data-href attribute missing from target element');
        }
      },
      open(href, close_on_click = true){
        this.vdata.ui_state.close_on_click = close_on_click
        this.vdata.ui_state.url = href
        this.vdata.ui_state.show = true
        this.vdata.controller.fetch()
      }
    }
  });
</script>

<style scoped>
  .modal {
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: 9998;
  }

  .modal.small .modal-container {
    height: 200px;
    width: 400px;
  }

  .modal.small .modal-body-content {
    height: 93px;
    text-align: center;
  }

  .modal-mask.small .modal-footer:after {
    display: none;
  }

  .modal-mask.small .modal-footer .button {
    margin: 0;
    width: 100%;
  }

  .modal-mask.small .modal-header h5 {
    font-size: 18px;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
    height: 548px;
    margin: 0px auto;
    padding: 0;
    position: relative;
    transition: all 0.3s ease;
    width: 847px;
  }

  .modal-header {
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    z-index: 2;
  }

  .modal-header h5 {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 1px;
  }

  .modal-header h5 + p {
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 1px;
    text-align: left;
  }

  .modal-header p {
    color: #828282;
    font-size: 13px;
    font-weight: 600;
    margin: 12px 0 0;
    text-align: center;
  }

  .modal-header .row .column:nth-child(2) .modal-header-content {
    border-left: 1px solid #E0E0E0;
  }

  .modal-header-content {
    padding: 20px 30px;
    height: 100%;
  }

  .modal-body .row .column:nth-child(1) .modal-body-content {
    background: #F9F9F9;
    padding: 20px 59px;
  }

  .modal-body .row .column:nth-child(2) .modal-body-content {
    border-left: 1px solid #E0E0E0;
  }

  .modal-body-content {
    height: 463px;
    overflow-y: scroll;
    padding: 20px 30px;
    position: relative;
    z-index: 1;
  }

  .modal-footer {
    position: relative;
  }

  .modal-footer:after {
    background: linear-gradient(to bottom, rgba(255,255,255,0), #fafafa 100%);
    bottom: 0;
    content: "";
    height: 25px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .modal-close {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .modal-close:hover {
    cursor: pointer;
  }

  .modal-close svg {
    width: 18px;
    height: 18px;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-container ul {
    list-style: none;
    margin: 0;
  }

  .modal-container li {
    text-transform: capitalize;
  }

  .modal-container label {
    color: #828282;
    cursor: pointer;
  }

  .modal-draggable-item {
    background: #fff;
    border: 1px solid #E0E0E0;
    color: #4F4F4F;
    cursor: move;
    margin: 0 0 9px;
    padding: 5px 12px;
    position: relative;
    text-transform: capitalize;
  }

  .modal-draggable-item-icon {
    bottom: 0;
    height: 20px;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 0;
    width: 20px;
  }

  @-webkit-keyframes rotate{
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate{
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .modal-loader {
    bottom: 0;
    cursor: wait;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .modal-loader:before {
    -webkit-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
    border-color: rgba(0,0,0,0.2);
    border-radius: 50%;
    border-style: solid;
    border-top-color: #41915C;
    border-width: 4px;
    bottom: 0;
    content: '';
    height: 45px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
  }
</style>
