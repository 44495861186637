/*
*
* The vue config is the same across our editor components
*
*/

import Vue3Filters from 'vue3-filters';
import VueSmoothScroll from 'v-smooth-scroll'
import 'maz-ui/css/main.css'
import { default as CurrencyInput } from 'admin/components/currency-input.vue';
import * as Sentry from "@sentry/vue";

const configureVueAppDefaults = function(app) {
  if(app._props && app._props.vdata){
    app.vdata = app._props.vdata;
  }


  /*
  *
  * What follows is a vendored version of vue-turbolinks, modified
  * to avoid resetting the outerHTML since it has the effect of partially
  * deleting page elements before the new page loads.
  *
  */

  function handleVueDestruction(vue) {
    const event = 'turbolinks:visit';
    document.addEventListener(event, function teardown() {
      console.log("Destroy");
      vue.$destroy();
      document.removeEventListener(event, teardown);
    });
  }

  const Mixin = {
    beforeMount: function() {
      if (this === this.$root && this.$el) {
        handleVueDestruction(this);
      }
    }
  };


  app.mixin(Mixin)


  app.use(Vue3Filters);

  // import { default as add_filters_to_vue } from 'shared/view-filters';
  // add_filters_to_vue(Vue);

  // import VueMatchHeights from 'vue-match-heights';
  // Vue.use(VueMatchHeights);


  app.component('currency-input', CurrencyInput);

  app.component('BaseEditor', {
    props: {
      vdata: {
        type:     Object,
        required: true
      }
    },
    render(createElement) {
      return this.vdata.controller.render_editor(createElement);
    }
  });


  app.use(VueSmoothScroll);

  Sentry.init({
    app,
    dsn: 'https://9115e70e332344f78f22b63a24f7dfa8@o393517.ingest.sentry.io/5260326',


    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

import { default as EventBus } from 'shared/event-bus';

export { configureVueAppDefaults, EventBus }
