<template>
  <div>
    <div :id="section_id(section.label)" class="position-relative">
      <Intersect
        :root-margin="intersect_config.root_margin"
        :threshold="intersect_config.threshold"
        @enter="handle_intersect"
        @leave="handle_intersect"
      >
        <div
          class="position-absolute"
          style="height: 100%; width: 1px;"
          :style="{ top: vdata.ui_state.subnav_offset - 10 + 'px' }"
        />
      </Intersect>
      <div class="panel__header">
        <div class="row align-bottom small-mb-0">
          <div class="column small-12 hide-for-large">
            <h5 class="panel__heading small-mt-21 small-mb-21 large-mt-0 large-mb-0">
              {{ section.label }}
            </h5>
          </div>
          <div class="column small-12 hide">
            <ul class="menu align-right">
              <li>
                <a href="#" class="link">
                  <span class="link__icon icon-print" />
                  <span class="link__text">
                    Print
                  </span>
                </a>
              </li>
              <li>
                <a href="#" class="link">
                  <span class="link__icon icon-download" />
                  <span class="link__text">
                    Download
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="panel__section small-pb-0">
        <div class="card card--large card--no-border card--no-shadow small-p-20 large-p-0">
          <div class="card__section small-p-0">
            <div class="row">
              <div class="column small-12 hide-for-large">
                <div class="accordion">
                  <div
                    v-for="(subsection, subsection_index) in section.subsections"
                    :key="`subsection${subsection_index}`"
                    class="accordion__item"
                    :class="{ 'accordion__item--active': mobile_active_accordion_items[subsection_index] }"
                  >
                    <button
                      v-if="section.subsections.length > 1"
                      class="accordion__title"
                      @click="toggle_accordion(subsection_index)"
                    >
                      {{ subsection.label }}
                    </button>
                    <div class="accordion__content hide-for-large" :class="{ 'accordion__content--active': mobile_active_accordion_items[subsection_index] }">
                      <Subsection :vdata="vdata" :subsection="subsection">
                        <component
                          :is="module.component"
                          v-for="(module, module_index) in subsection.data.modules"
                          :key="`module${module_index}`"
                          :module="module"
                          :index="module_index"
                          :vdata="vdata"
                        >
                          <Edit
                            button_position="right"
                            button_text="Edit"
                            :vdata="vdata"
                            :editor_type="module.component"
                            :editor_target="subsection"
                            :editor_target_address="{field: 'modules', index: module_index}"
                            :add_module_callback="add_module(subsection, module_index)"
                            @move_up="vdata.controller.module_control('up', subsection, module_index)"
                            @move_down="vdata.controller.module_control('down', subsection, module_index)"
                            @delete_module="vdata.controller.module_control('delete', subsection, module_index)"
                          />
                        </component>
                      </Subsection>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column small-12 large-11 show-for-large">
                <div class="row">
                  <div class="columns shrink">
                    <span class="card__nav_title">{{ section.label }}</span>
                  </div>
                  <div class="columns small-pl-0">
                    <hr class="card__hr card__hr--title">
                  </div>
                </div>
              </div>
              <div class="column large-3 show-for-large large-pr-0">
                <Edit
                  button_position="left"
                  button_text="Edit Subsections"
                  type="modal"
                  :vdata="vdata"
                  editor_type="section_list"
                  :editor_target="section"
                  :editor_target_address="{field: 'subsections'}"
                />
                <div class="card__nav">
                  <!-- <span class="card__nav_title">{{ section.label }}</span> -->
                  <div class="accordion accordion--deal-subnav">
                    <div
                      v-for="(subsection, subsection_index) in section.subsections"
                      :key="`subsection${subsection_index}`"
                      class="accordion__item"
                      :class="{ 'accordion__item--active': current_accordion_item == subsection_index }"
                      @click="toggle_accordion(subsection_index)"
                    >
                      <button class="accordion__link">
                        {{ subsection.label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column large-8 show-for-large large-pt-25 large-pb-20">
                <div
                  v-for="(subsection, subsection_index) in section.subsections"
                  :key="`subsection${subsection_index}`"
                  class="accordion__content"
                  :class="{ 'accordion__content--active': current_accordion_item == subsection_index }"
                >
                  <Subsection :vdata="vdata" :subsection="subsection">
                    <component
                      :is="module.component"
                      v-for="(module, module_index) in subsection.data.modules"
                      :key="`module${module_index}`"
                      :module="module"
                      :index="module_index"
                      :vdata="vdata"
                    >
                      <Edit
                        button_position="right"
                        button_text="Edit"
                        :vdata="vdata"
                        :editor_type="module.component"
                        :editor_target="subsection"
                        :editor_target_address="{field: 'modules', index: module_index}"
                        :add_module_callback="add_module(subsection, module_index)"
                        @move_up="vdata.controller.module_control('up', subsection, module_index)"
                        @move_down="vdata.controller.module_control('down', subsection, module_index)"
                        @delete_module="vdata.controller.module_control('delete', subsection, module_index)"
                      />
                    </component>
                  </Subsection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as Edit } from './edit.vue';
import { default as FullWidthImage } from './modules/full-width-image.vue';
import { default as Managers } from './modules/managers.vue';
import { default as PhotoGallery } from './modules/photo-gallery.vue';
import { default as Subsection } from './subsection.vue';
import { default as TextBlock } from './modules/text-block.vue';
import { default as VideoEmbed } from './modules/video-embed.vue';
import Intersect from 'vue-intersect';

export default {
  name: "Section",
  components: {
    full_width_image: FullWidthImage,
    managers:         Managers,
    photo_gallery:    PhotoGallery,
    text_block:       TextBlock,
    video_embed:      VideoEmbed,
    Edit,
    Intersect,
    Subsection
  },
  props: {
    section: {
      type:     Object,
      required: true
    },
    vdata: {
      type:     Object,
      required: true
    }
  },
  data() {
    let mobile_state = {}
    this.section.subsections.forEach( (a,b) => {
      mobile_state[b] = b === 0;
    })
    return {
      current_accordion_item: 0,
      mobile_active_accordion_items: mobile_state,
      intersect_config: {
        root_margin: '0px 0px -100%',
        threshold:   [0, 1]
      }
    }
  },
  methods: {
    add_module(target, index) {
      if(this.vdata.ui_state.edit_mode){
        return(this.vdata.controller.module_control.bind(this, 'insert', target, index));
      }else{
        return(()=>{});
      }
    },
    handle_intersect(IntersectionObserverEntry) {
      if (IntersectionObserverEntry[0].isIntersecting && IntersectionObserverEntry[0].intersectionRatio === 0) {
        this.vdata.ui_state.current_section = this.section.label;
      }
    },
    section_id(label) {
      if (!label) return
      label = label.toLowerCase();
      label = label.replace(/ /g, '_');
      return label;
    },
    toggle_accordion(index) {
      if(this.section.subsections.length > 1){
        if(this.mobile_active_accordion_items[index]){
          let newval = Object.assign( {}, this.mobile_active_accordion_items )
          newval[index] = false
          this.mobile_active_accordion_items = newval
        }else{
          let newval = Object.assign( {}, this.mobile_active_accordion_items )
          newval[index] = true
          this.mobile_active_accordion_items = newval
        }
      }
      this.current_accordion_item = index;
    }
  }
};
</script>
