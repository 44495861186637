<template>
  <div class="photo-gallery deal__module">
    <slot />
    <div class="row collapse small-up-2 large-up-3">
      <div v-for="(image, index) in module.images" :key="`image${index}`" class="column">
        <div class="photo-gallery__box box box--radius">
          <img :src="image.url" class="image image--expanded image--radius">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhotoGallery",
  props: {
    module: {
      type:     Object,
      required: true
    }
  }
};
</script>
