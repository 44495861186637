<template>
  <Modal
    :show="show"
    size="medium"
    data-cy="modal-confirm-document-status"
    @close="close"
  >
    <template #header>
      <div class="modal__content modal__content--header">
        <h5 class="modal__heading">
          {{ content.title }}
        </h5>
      </div>
    </template>
    <template #body>
      <div class="modal__content modal__content--body">
        <div class="modal__text">
          <p>Are you sure you want to change the status for this <strong>{{ content.object_type }}</strong> from <strong>{{ content.current_status }}</strong> to <strong>{{ content.proposed_status }}</strong>?</p>
        </div>

        <template v-if="fields.expiration_date">
          <div class="row">
            <div class="column small-12">
              <label class="input-label">Expiration Date</label>
              <Datepicker
                v-model="expiration_date"
                class="input"
                placeholder="Date"
              />
            </div>
          </div>
        </template>

        <template v-if="fields.reason">
          <label class="input-label">Reason</label>
          <input v-model="reason" class="input" type="text">
          <p class="text-center">
            <strong>
              This reason is not an internal note and will be included in an email to the investor.
            </strong>
          </p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="modal__content modal__content--footer">
        <div class="row">
          <div class="column small-6">
            <button type="button" class="button button--secondary expanded" @click="close">
              Cancel
            </button>
          </div>
          <div class="column small-6">
            <button
              type="button"
              class="button expanded"
              data-cy="modal-confirm-document-status__confirm-status"
              @click="update"
            >
              Yes, Change Status
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { default as Modal } from '../../shared/modal.vue';
import Datepicker from 'admin/components/date-picker.vue';
import { parseISO } from 'date-fns';
import { add } from 'date-fns';
import { formatRFC3339 } from 'date-fns';

export default {
  name: 'ModalConfirmDocumentStatus',
  components: {
    Modal,
    Datepicker
  },
  props: {
    content: {
      required: true,
      type: Object,
      validator: (content)=> {
        return content.title != null &&
        content.object_type != null &&
        content.current_status != null;
      }
    },
    fields: {
      required: true,
      type: Object,
      validator: (fields)=> {
        return typeof fields.reason === "boolean" &&
        typeof fields.expiration_date === "boolean";
      }
    },
    show: Boolean
  },
  data() {
    return this.reset_data.call(this);
  },
  watch: {
    show(){
      Object.assign(this.$data, this.reset_data());
    }
  },
  methods: {
    close() {
      return this.$emit('cancel');
    },
    reset_data() {
      return {
        expiration_date: this.content.proposed_expiration_date ? parseISO(this.content.proposed_expiration_date, new Date()) : add(new Date(), {days: 90}),
        reason:          ""
      };
    },
    update() {
      this.$data.expiration_date = this.fields.expiration_date ? formatRFC3339(this.$data.expiration_date) : null;
      this.$emit('resolve', this.$data);
    }
  }
};
</script>

<style>
.vdp-datepicker__calendar {
  white-space: normal;
}
</style>
