<template>
  <DealLayout :vdata="vdata">
    <template v-slot:hero>
      <Hero :vdata="vdata" />
    </template>
    <template v-slot:subnav>
      <Subnav :vdata="vdata" />
    </template>
    <template v-slot:sections>
      <div class="panel panel--large overflow-visible position-relative">
        <Section
          v-for="section in vdata.details.sections"
          :key="section.label"
          :section="section"
          :vdata="vdata"
        />
      </div>
    </template>
    <template v-slot:modals>
      <EditModal :show="vdata.ui_state.show_edit_modal" :vdata="vdata" />
      <NoticeModal v-bind="vdata.ui_state.notice_modal" />
    </template>
  </DealLayout>
</template>

<script>
import { default as DealLayout } from './components/deal/deal-layout.vue';
import { default as EditModal } from './components/deal/edit-modal.vue';
import { default as Hero } from './components/deal/hero.vue';
import { default as NoticeModal } from 'shared/notice-modal.vue';
import { default as Section } from './components/deal/section.vue';
import { default as Subnav } from './components/deal/subnav.vue';

export default {
  name: "Deal",
  components: {
    DealLayout,
    EditModal,
    Hero,
    NoticeModal,
    Section,
    Subnav
  },
  props: {
    vdata: {
      type:     Object,
      required: true
    }
  },
  mounted() {
    if(this.vdata.deal_page && this.vdata.deal_page.id && this.vdata.ui_state.edit_mode){
      this.vdata.controller.start_pinging();
    }
  },
  beforeDestroy() {
    if(this.vdata.ui_state.edit_mode){
      this.vdata.controller.stop_pinging();
    }
  }
};
</script>
