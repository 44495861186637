<template>
  <transition name="modal">
    <div v-if="show" class="modal" :class="size_class">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <button v-if="show_close_button" class="modal__close" @click="$emit('close')">
              <span class="modal__close-icon icon-times" />
            </button>
            <slot name="header" />
          </div>

          <div class="modal__body">
            <slot name="body" />
          </div>

          <div class="modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from 'shared/event-bus';

export default
  ({
    name: "Modal",
    props: {
      show: Boolean,
      show_close_button: {
        type:    Boolean,
        default: true
      },
      size: {
        type:    String,
        default: 'medium'
      }
    },
    computed: {
      size_class: function() {
        return `modal--${this.size}`;
      }
    },
    watch: {
      show: function() {
        if (this.size === 'xlarge') {
          $('body').toggleClass('body--lock-scroll')
        }
      }
    },
    mounted() {
      EventBus.$on('keypress:esc', this.emitClose);
    },
    beforeDestroy() {
      EventBus.$off('keypress:esc', this.emitClose);
    },
    methods: {
      emitClose() {
        if(this.show){
          this.$emit('close');
        }
      }
    }
  });
</script>

<style scoped>
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal__container,
  .modal-leave-active .modal__container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
