<template>
  <tbody data-cy="table__body">
    <tr
      v-for="row in vdata.result.rows"
      :key="row.id"
      class="table__tr"
      data-cy="tabular-data__table-tr"
      :data-db-id="row.id"
    >
      <td
        v-for="column in vdata.ui_state.active_columns"
        :key="vdata.controller.colDefs[column].label"
        class="table__td"
        data-cy="tabular-data__table-td"
      >
        <BaseCell
          :row="row"
          :col-def="vdata.controller.colDefs[column]"
          :column="column"
          :ui_defs="vdata.ui_defs"
        />
      </td>
      <td v-if="vdata.controller.show_actions" class="table__td" data-cy="tabular-data__table-td">
        <Actions :row="row" :vdata="vdata" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { default as Actions } from './cells/actions.vue';
require('./cells/base').default;

export default {
  name: 'TBody',
  components: {
    Actions
  },
  props: {
    vdata: {
      type: Object,
      required: true
    }
  },
};
</script>
