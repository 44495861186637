const UserPassword = function(options = {}){
  if(typeof(options.allow_empty) === "undefined" ){
    options.allow_empty = false;
  }
  return function($el) {
    const $error = $el.next('span');
    const currentValue = $el.val();
    const minLength = 12;
    let error_text;
    let is_valid = true;

    if (currentValue === '') {
      $('[data-password-requirement]').removeClass('valid');

      if (options.allow_empty){
        return true;
      } else{
        $error.text('This field is required');
        return false;
      }
    }

    const requirements = {
      lowercase: {
        validator: !currentValue.match(/[a-z]+/),
        text:      'Include at least one lowercase letter'
      },
      uppercase: {
        validator: !currentValue.match(/[A-Z]+/),
        text:      'Include at least one uppercase letter'
      },
      number: {
        validator: !currentValue.match(/[0-9]+/),
        text:      'Include at least one number'
      },
      symbol: {
        validator: !currentValue.match(/[#?!@$%^&*-]+/),
        text:      'Include at least one symbol'
      },
      length: {
        validator: parseInt(currentValue.length) < parseInt(minLength),
        text:      `Passwords must be at least ${minLength} characters`
      }
    }

    Object.keys(requirements).forEach(key=> {
      if (requirements[key].validator) {
        $(`[data-password-requirement=${key}]`).removeClass('valid');
        error_text = requirements[key].text;
        is_valid = false;
      } else {
        $(`[data-password-requirement=${key}]`).addClass('valid');
      }
    })

    if (!is_valid) {
      $error.text(error_text);
    }

    return is_valid;
  };
}

export default UserPassword;
