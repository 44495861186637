<template>
  <div data-cy="confirm-status-select">
    <select
      class="select select--collapse select--small"
      :data-cy="`confirm-status-select__${object_type}`"
      :value="target_object[target_attribute]"
      @change="confirm_status_change"
    >
      <option v-for="(l,v) in status_labels[object_type]" :key="v" :value="v">
        {{ l }}
      </option>
    </select>
    <ConfirmStatusModal
      :content="modal_content()"
      :fields="modal_fields()"
      :show="show_modal"
      @cancel="cancel"
      @resolve="resolve_modal"
    />
  </div>
</template>

<script>
import { default as ConfirmStatusModal } from './modal-confirm-status.vue';

export default
  ({
    name: "ConfirmStatusSelect",
    components: {
      ConfirmStatusModal
    },
    props: {
      object_type: {
        type:     String,
        required: true
      },
      model_id: {
        type:     [Number, String],
        required: true
      },
      offer_document_id: {
        type:      Number,
        required:  false,
        default:   null
      },
      target_object: {
        type:     Object,
        required: true
      },
      target_object_type: {
        type:     String,
        required: true
      },
      target_attribute: {
        type:     String,
        required: true
      },
      status_labels: {
        type:     Object,
        required: true
      },
      on_status_change: {
        type:     Function,
        required: true
      }
    },
    data() {
      return {
        proposed_status: false,
        show_modal: false
      };
    },
    methods: {
      confirm_status_change(e) {
        this.proposed_status = e.target.value;
        this.show_modal = true;
      },
      cancel() {
        this.show_modal = false;
      },
      modal_content() {
        return {
          title:                      "Document Status Change",
          object_type:                this.target_object_type,
          current_status:             this.status_labels[this.object_type][this.target_object[this.target_attribute]],
          proposed_status:            this.status_labels[this.object_type][this.proposed_status],
          proposed_expiration_date:   this.target_object.expiration_date
        };
      },
      modal_fields() {
        return {
          reason:             ['rejected_retrying', 'rejected'].includes(this.proposed_status),
          expiration_date:    (this.object_type === 'accreditation') && !['rejected_retrying', 'rejected'].includes(this.proposed_status)
        };
      },
      resolve_modal(additional_data){
        additional_data.offer_document_id = this.offer_document_id;
        this.on_status_change(this.object_type, this.model_id, this.proposed_status, additional_data);
        this.show_modal = false;
      }
    }
  });
</script>

<style scoped>
.select {
  width: 150px;
}
</style>
