/*
 *
 * @file boundFunctions.js
 * DRYs out binding functions to their class context in our modules.
 *
 * @param fs Array of instance method names
 * @param reference to instance (this)
 */
export default function(fs, ctx) {
  fs.map((f) => {
    ctx[f] = ctx[f].bind(ctx)
  })
}
