<template>
  <Modal
    :show="show"
    :show_close_button="false"
    size="xlarge"
  >
    <template #body>
      <div class="modal__content modal__content--body">
        <BaseEditor
          v-if="vdata.ui_state.show_edit_modal"
          :vdata="vdata"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { default as Modal } from 'shared/modal.vue';

export default {
  name: 'EditModal',
  components: {
    Modal
  },
  props: {
    show: {
      type:     Boolean,
      required: true
    },
    vdata: {
      type:     Object,
      required: true
    }
  }
};
</script>
