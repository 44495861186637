<template>
  <div class="pagination flex-container align-middle">
    <div class="flex-child-grow">
      <div class="row align-middle text-right">
        <div class="column">
          <div class="pagination__results">
            <div class="row align-middle align-right">
              <div class="column shrink">
                <label class="input-label input-label--capitalize input-label--large text-right" for="results">Results Per Page</label>
              </div>
              <div class="column shrink">
                <select
                  :value="vdata.ui_state.params.pp"
                  name="results"
                  class="pagination__select select select--collapse select--small"
                  @input="set_per_page"
                >
                  <option value="25">
                    25
                  </option>
                  <option value="50">
                    50
                  </option>
                  <option value="75">
                    75
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column shrink">
          <nav aria-label="Pagination">
            <div class="row align-middle collapse">
              <div class="column shrink">
                <button
                  v-if="vdata.ui_state.params.page != 1"
                  type="button"
                  class="pagination__button button button--secondary button--small"
                  aria-label="Previous Page"
                  @click="vdata.ui_state.params.page--"
                >
                  <span class="button__icon button__icon--centered icon-chevron-left" />
                </button>
              </div>
              <div
                v-for="page in filtered_total_pages"
                :key="page"
                class="column shrink"
              >
                <button
                  type="button"
                  class="pagination__button button button--secondary button--small"
                  :class="{
                    'pagination__button--current': page == vdata.ui_state.params.page,
                    'pagination__button--ellipsis-first': (page == 1 & Math.abs(page - vdata.ui_state.params.page) > 3),
                    'pagination__button--ellipsis-last': (page == total_pages - 1 && Math.abs(page - vdata.ui_state.params.page) > 3)
                  }"
                  :aria-label="'Page ' + page"
                  @click="set_page(page)"
                >
                  {{ page }}
                </button>
              </div>
              <div class="column shrink">
                <button
                  v-if="vdata.ui_state.params.page != total_pages"
                  type="button"
                  class="pagination__button button button--secondary button--small"
                  aria-label="Next Page"
                  @click="vdata.ui_state.params.page++"
                >
                  <span class="button__icon button__icon--centered icon-chevron-right" />
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    vdata: {
      type: Object,
      required: true
    }
  },
  computed: {
    total_pages() {
      if (!this.vdata.result.total) {
        return 0;
      }
      return Math.ceil(this.vdata.result.total / this.vdata.ui_state.params.pp);
    },
    filtered_total_pages() {
      const pages = [];
      for(let page=1; page<=this.total_pages; page++){
        if(Math.abs(page - this.vdata.ui_state.params.page) < 3 || page == this.total_pages - 1 || page == 1){
          pages.push(page);
        }
      }
      return pages;
    }
  },
  methods: {
    fetch() {
      this.vdata.controller.fetch();
    },
    set_page(page){
      this.vdata.ui_state.params.page = page;
    },
    set_per_page(e){
      const pp = parseFloat(e.target.value);
      const new_total_pages = this.vdata.result.total ? Math.ceil(this.vdata.result.total / pp) : 1;
      if (new_total_pages < this.vdata.ui_state.params.page) {
        this.vdata.ui_state.params.page = new_total_pages;
      }
      this.vdata.ui_state.params.pp = pp;
    }
  }
};
</script>
