/*
 *
 * @file deal
 *
 */

import { configureVueAppDefaults } from 'shared/vue-configured';
import { createApp } from "vue";

const App = require("./deal.vue").default;

class Deal {
  constructor(app, config) {
    this.config = config;
    this.init = this.init.bind(this);
    this.set_default_ui_state = this.set_default_ui_state.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$("[data-vue-target=deal]").length) {
      return;
    }
    let base_data = window.deal_data;

    base_data.controller = this;
    base_data.ui_state = {};

    if (this.editor_init) {
      this.editor_init(base_data);
    } else {
      this.editor_components = {}; //
    }

    this.set_default_ui_state(base_data);

    this.vm = createApp(App, { vdata: base_data })
      .component("BaseEditor", {
        props: {
          vdata: {
            type: Object,
            required: true,
          },
        },
        render() {
          return this.vdata.controller.render_editor();
        },
      });
      configureVueAppDefaults(this.vm);
      this.vm.mount("[data-vue-target=deal]");
    // this.vm = new Vue({
    //   el: '[data-vue-target=deal]',
    //   components: { App },
    //   data() {
    //     return {vdata: base_data};
    //   },
    //   template: '<App v-bind:vdata="vdata"/>'
    // });

    return (window._vm = this.vm);
  }

  set_default_ui_state(base_data) {
    let first_label;

    if (!this.editor_init) {
      first_label =
        base_data.details.sections[0] != null
          ? base_data.details.sections[0].label
          : "default";
    }

    base_data.ui_state = Object.assign(
      {
        current_section: first_label ? first_label : "",
        edit_mode: false,
        subnav_offset: 0,
      },
      base_data.ui_state
    );
  }
}

export default Deal;
