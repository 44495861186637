<template>
  <div class="deal__module">
    <slot />
    <h3 v-if="module.heading" class="deal__subsection-heading">
      {{ module.heading }}
    </h3>
    <div v-html="module.lead" />
    <div v-for="(manager, manager_index) in module.managers" :key="`manager${manager_index}`" class="row">
      <div class="column small-12 large-4">
        <div class="deal__subsection-box box box--radius">
          <img :src="manager.image.url" class="image image--expanded image--radius">
        </div>
      </div>
      <div class="column small-12 large-8">
        <h4 v-if="manager.name || manager.position" class="deal__subsection-subheading">
          {{ manager.name }}
          <template v-if="manager.position">
            |
          </template> {{ manager.position }}
        </h4>
        <div v-html="manager.excerpt" />
        <a :href="manager.link">
          {{ manager.link_label }}
        </a>
      </div>
      <div v-if="!manager_index == module.managers.length -1" class="column small-12">
        <hr class="deal__subsection-hr hr">
      </div>
    </div>
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */
export default {
  name: "Managers",
  props: {
    module: {
      type:     Object,
      required: true
    }
  }
};
</script>
