/*
 *
 * @file modal-ajax
 *
 * Usage:
 *
 * <button type="button"  data-href="/url_path" data-ajax-modal>Button</button>
 *
 * <a href="/url_path" data-ajax-modal>Anchor Link</a>
 *
 * By default, clicking outside of the modal will close it. To disable this behavior,
 * add data-close-on-click="false" to the element:
 *
 * <a href="/url_path" data-ajax-modal data-close-on-click="false">Anchor Link</a>
 *
 */



import { createApp } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';

const App = require('./ajax-modal.vue').default;

class AjaxModal {

  constructor(app) {
    this.init = this.init.bind(this);
    this.fetch = this.fetch.bind(this);
    this.receive = this.receive.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    const root = "[data-vue-target=ajax-modal]";
    const $root = $(root);

    if (!$root.length) {
      return;
    }

    const base_data = {};
    base_data.ui_state = this.default_ui_state();
    base_data.controller = this;
    this.vm = createApp(App, { vdata: base_data });
    configureVueAppDefaults(this.vm);
    this.vm.mount(root);
    // this.vm = new Vue({
    //   el: root,
    //   components: { App },
    //   data() {
    //     return {vdata: base_data};
    //   },
    //   template: '<App v-bind:vdata="vdata"/>'
    // });
  }

  default_ui_state() {
    return {
      close_on_click: true,
      html:           '',
      loading:        false,
      show:           false,
      url:            ''
    };
  }

  fetch() {
    return new Promise((resolve, reject) => {

      this.vm.vdata.ui_state.loading = true;

      $.ajax({
        url: this.vm.vdata.ui_state.url,
        success: this.receive.bind(this, resolve, reject),
        error: this.fetch_failure.bind(this, reject),
        complete: () => {
          this.vm.vdata.ui_state.loading = false;
        }
      });
    });
  }

  fetch_failure(reject, jqXHR) {
    console.log(jqXHR);
    reject();
  }

  receive(resolve, reject, response) {
    this.vm.vdata.ui_state.html = response;
    resolve(response);
  }
}

export default AjaxModal;
