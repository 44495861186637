<template>
  <div>
    <!-- Mobile -->
    <div class="hide-for-large">
      <div class="panel panel--large small-pt-24 small-pb-14 overflow-visible">
        <div class="panel__section">
          <div class="card card--status-new overflow-visible">
            <div class="card__content">
              <a href="#" class="card__link">
                <div class="card__header">
                  <h5 class="card__heading">
                    {{ vdata.deal.name }}
                  </h5>
                  <div class="card__subheading">
                    {{ vdata.deal.deal_display_location }}
                  </div>
                </div>


                <div class="card__image" :style="{'background-image': `url(${vdata.details.hero_data.images[0] ? vdata.details.hero_data.images[0].url : ''})`}">
                  <div class="card__tag" :class="{'stats__tag--live': !closed_or_realized}">
                    {{ tag_content.primary }}
                  </div>
                  <div v-if="tag_content.show_secondary_tag" class="card__tag card__tag--invested-date">
                    INVESTED AS OF {{ ac_short_date(vdata.ui_defs.date_invested) }}
                  </div>
                </div>
                <div class="card__divider">
                  <div class="row">
                    <div class="small-6 column">
                      <span class="text-bold text-primary-condensed">
                        {{ vdata.details.hero_data.marquee_stats[0].label }}
                      </span>
                      <span class="text-bold">
                        {{ vdata.details.hero_data.marquee_stats[0].value }}
                      </span>
                    </div>
                    <div v-if="vdata.details.hero_data.marquee_stats[1]" class="small-6 column text-right">
                      <span class="text-bold text-primary-condensed">
                        {{ vdata.details.hero_data.marquee_stats[1].label }}
                      </span>
                      <span class="text-bold">
                        {{ vdata.details.hero_data.marquee_stats[1].value }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card__section">
                  <table class="table table--secondary">
                    <tbody class="table__tbody">
                      <tr v-for="stat in vdata.details.hero_data.stats" :key="stat.label" class="table__tr">
                        <td class="table__td">{{ stat.label }}</td>
                        <td class="table__td">
                          {{ stat.value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card__meta">
                    {{ vdata.details.hero_data.deal_asset_class }}, {{ vdata.details.hero_data.build_type }}
                  </div>
                </div>
              </a>
            </div>
            <div class="row small-mt-16 small-mb-16">
              <div class="column small-6">
                <div class="subpanel subpanel--bordered subpanel--secondary">
                  <h6 class="subpanel__heading">
                    Investment Type
                  </h6>
                  <div class="subpanel__text">
                    {{ vdata.details.hero_data.investment_type }}
                  </div>
                </div>
              </div>
              <div class="column small-6">
                <div class="subpanel subpanel--bordered subpanel--secondary">
                  <h6 class="subpanel__heading">
                    Minimum Investment
                  </h6>
                  <div class="subpanel__text">
                    {{ vdata.details.hero_data.minimum_investment }}
                  </div>
                </div>
              </div>
            </div>
            <InvestCTA :vdata="vdata" />
            <p>
              {{ vdata.details.hero_data.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="panel panel--large">
        <div class="panel__header">
          <h6 class="panel__heading">
            Deal Documents
          </h6>
        </div>
        <div class="panel__section">
          <div class="row align-center collapse">
            <div class="column small-10">
              <p v-for="doc in vdata.details.hero_data.documents" :key="doc.name" class="panel__text">
                <span class="display-block text-bold">
                  {{ doc.name }}
                </span>
                <a :href="doc.document.url" target="_blank" class="link link--underline">
                  <span class="link__icon icon-view" />
                  <span class="link__text">
                    View PDF
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="panel panel--large">
        <div class="panel__header">
          <h6 class="panel__heading text-bold text-primary">
            Event Module
          </h6>
        </div>
        <div class="panel__section">
          <p>
            Sign up for the upcoming event: Lorem ipsum dolor set amet conste tur adipiscing el lit, sed do eiusnod.
          </p>
        </div>
        <div class="panel__section">
          <div class="panel__image" style="background-image: url(https://placehold.it/500x500?text=placeholder)" />
        </div>
        <div class="panel__section">
          <a href="#" class="button button--expanded button--hollow">
            Sign Up
          </a>
        </div>
      </div>
      <div class="panel panel--large">
        <div class="panel__header">
          <h6 class="panel__heading text-bold text-primary">
            Questions
          </h6>
          <p>
            Have a question about this deal? Submit it below and our team will get back to you as soon as possible.
          </p>
          <a href="/contact" class="button button--expanded button--hollow">
            Submit a Question
          </a>
        </div>
      </div>
    </div>
    <!-- /Mobile -->

    <!-- Desktop -->
    <div class="show-for-large">
      <div class="panel panel--large">
        <div class="panel__header">
          <div class="row align-justify small-mb-0">
            <div class="column large-7">
              <h1 class="panel__heading small-mb-5">
                {{ vdata.deal.name }}
                <span class="panel__heading--separator">|</span>
                <span class="deal__location">{{ vdata.deal.deal_display_location }}</span>
              </h1>
              <div class="deal__types small-mb-0">
                {{ vdata.details.hero_data.deal_asset_class }} <span>/</span> {{ vdata.details.hero_data.investment_type }} INVESTMENT
              </div>
            </div>
            <div class="column text-right">
              <InvestCTA :vdata="vdata" />
            </div>
          </div>
        </div>
        <div class="panel__section">
          <div class="card card--large card--border-light-gray card--no-shadow">
            <Edit
              button_text="Edit Photos"
              type="modal"
              :vdata="vdata"
              editor_type="photo_gallery"
              :editor_target="vdata.details.hero_data"
              :editor_target_address="{field: 'images'}"
            />

            <Edit
              button_position="right"
              button_text="Edit Stats"
              type="modal"
              :vdata="vdata"
              editor_type="stats"
              :editor_target="vdata.details.hero_data"
            />

            <div class="row">
              <div class="column small-7 position-relative">
                <div class="stats__tag stats__tag--agile" :class="{'stats__tag--live': !closed_or_realized}">
                  {{ tag_content.primary }}
                </div>
                <div v-if="tag_content.show_secondary_tag" class="stats__tag stats__tag--invested-date">
                  INVESTED AS OF {{ ac_short_date(vdata.ui_defs.date_invested) }}
                </div>
                <div class="card__divider card__divider--carousel">
                  <div class="row collapse">
                    <div class="small-6 column">
                      <span class="text-primary small-mb-2">
                        {{ vdata.details.hero_data.marquee_stats[0].label }}:
                      </span>
                      <span class="text-bold">
                        {{ vdata.details.hero_data.marquee_stats[0].value }}
                      </span>
                    </div>
                    <div v-if="vdata.details.hero_data.marquee_stats[1]" class="small-6 column text-right">
                      <span class="text-primary small-mb-2">
                        {{ vdata.details.hero_data.marquee_stats[1].label }}:
                      </span>
                      <span class="text-bold">
                        {{ vdata.details.hero_data.marquee_stats[1].value }}
                      </span>
                    </div>
                  </div>
                </div>
                <VueAgile
                  ref="agile"
                  :key="vue_agile_key"
                  :options="carousel_options"
                  class="deal__agile agile"
                >
                  <div
                    v-for="image in vdata.details.hero_data.images"
                    :key="image.deal_page_document_id"
                    class="deal__agile-slide agile__slide"
                    :style="{'background-image': `url(${image.url})`}"
                  />
                </VueAgile>
              </div>
              <div class="column small-5">
                <div class="row collapse small-mt-30 small-mb-30 small-pl-40 small-pr-40">
                  <div class="column small-12 text-center">
                    <p class="card__text card__text--description small-mb-0">
                      {{ vdata.details.hero_data.description }}
                    </p>
                  </div>
                </div>
                <div class="card__stats card__stats--solid-bg card__stats--no-radius card__stats--summary">
                  <div class="card__section">
                    <table
                      class="table table--deal-summary"
                      :class="{'table--closed_or_realized': closed_or_realized}"
                    >
                      <tbody class="table__tbody">
                        <tr
                          v-for="stat in vdata.details.hero_data.stats"
                          :key="stat.label"
                          :class="{'table__tr--strong': stat.strong, 'table__tr--divider': stat.show_divider}"
                          class="table__tr"
                        >
                          <td class="table__td table__td--label">
                            {{ stat.label }}
                          </td>
                          <td class="table__td">
                            {{ stat.value }}
                          </td>
                        </tr>
                        <!-- <tr
                          class="table__tr table__tr--strong table__tr--divider"
                          v-if="!closed_or_realized"
                        >
                          <td class="table__td">
                            Minimum Investment:
                          </td>
                          <td class="table__td">
                            {{ vdata.details.hero_data.minimum_investment }}
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  v-if="closed_or_realized"
                  class="row small-mt-0 small-pl-30 small-pr-30"
                >
                  <div class="column">
                    <table class="table table--deal-summary">
                      <tbody class="table__tbody">
                        <tr class="table__tr">
                          <td class="table__td">
                            Minimum Investment:
                          </td>
                          <td class="table__td">
                            {{ vdata.details.hero_data.minimum_investment }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel__section">
          <div class="row">
            <div class="column small-6 large-7">
              <div class="card card--small card--no-shadow card--border-light-gray card--full-height small-pl-16 small-pr-16">
                <Edit
                  button_text="Edit Documents"
                  type="modal"
                  :vdata="vdata"
                  editor_type="document_list"
                  :editor_target="vdata.details.hero_data"
                  :editor_target_address="{field: 'documents'}"
                />

                <div class="card__header">
                  <h6 class="card__heading card__heading--small small-mb-15">
                    Deal Documents
                  </h6>
                </div>
                <div class="card__section">
                  <div v-for="(doc, index) in vdata.details.hero_data.documents" :key="doc.name" class="row align-middle">
                    <div v-if="index > 0" class="column small-12">
                      <hr class="card__hr hr">
                    </div>
                    <div class="column small-6 large-9">
                      <div class="card__text">
                        {{ doc.name }}
                      </div>
                    </div>
                    <div class="column small-6 large-3 text-right">
                      <a :href="doc.document.url" target="_blank" class="link link--underline link--small">
                        <span class="link__icon icon-view" />
                        <span class="link__text">
                          View PDF
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="column small-3">
              <div class="card card--small">
                <div class="card__header">
                  <h6 class="card__heading card__heading--small">
                    Event Module
                  </h6>
                </div>
                <div class="card__section">
                  <div class="row collapse large-height-full">
                    <div class="column small-12">
                      <div class="card__text">
                        Sign up for the upcoming event.
                      </div>
                    </div>
                    <div class="column small-12 align-self-bottom">
                      <div class="text-right">
                        <a href="#" class="link link--underline link--small">
                          <span class="link__text">
                            View Event
                          </span>
                          <span class="link__icon icon-chevron-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="column small-6 large-5">
              <div class="card card--small card--no-shadow card--border-light-gray card--full-height small-pl-16 small-pr-16">
                <div class="card__header">
                  <h6 class="card__heading card__heading--small small-mb-15">
                    Questions
                  </h6>
                </div>
                <div class="card__section">
                  <div class="row large-height-full">
                    <div class="column small-12">
                      <div class="card__text">
                        Have a question about this deal? Submit it below and our team will get back to you.
                      </div>
                    </div>
                    <div class="column small-12 align-self-bottom">
                      <div class="text-right">
                        <a href="/contact" class="link link--underline link--small">
                          <span class="link__text">
                            Submit a Question
                          </span>
                          <span class="link__icon icon-chevron-right-small" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Desktop -->
  </div>
</template>

<script>
import { default as Edit } from './edit.vue';
import { EventBus } from 'shared/vue-configured';
import { VueAgile } from 'vue-agile';
import InvestCTA from './invest-cta.vue';
import { ac_short_date } from 'shared/view-filters';

export default {
  name: "Hero",
  components: {
    Edit,
    InvestCTA,
    VueAgile,
  },
  props: {
    vdata: {
      type:     Object,
      required: true
    }
  },
  data() {
    return {
      carousel_options: {
        dots: true
      },
      vue_agile_key: 1
    }
  },
  computed: {
    closed_or_realized(){
      const status = this.vdata.deal.deal_status;
      if(status == "realized" || status == "closed"){
        return status;
      }else{
        return false;
      }
    },
    closed_or_realized_date(){
      const status = this.vdata.deal.deal_status;
      if(status == "realized"){
        return ac_short_date(this.vdata.deal.date_realized)
      }
      if(status == "closed"){
        return ac_short_date(this.vdata.deal.date_closed)
      }
      return "";
    },
    tag_content() {
      let r = {
        show_secondary_tag: this.vdata.ui_defs.has_investments && !this.closed_or_realized
      };
      if(this.closed_or_realized){
        r.primary = `${this.closed_or_realized} as of ${this.closed_or_realized_date}`.toUpperCase();
      }else{
        if(this.vdata.ui_defs.has_investments){
          r.show_secondary_tag = true;
          r.secondary_tag = `INVESTED AS OF ${ac_short_date(this.vdata.ui_defs.date_invested)}`;
        }
        r.primary = this.vdata.deal.deal_status_human.toUpperCase();
      }
      return r;
    }
  },
  beforeDestroy() {
    EventBus.$off('deal:reload_agile', this.reload_agile);
  },
  mounted() {
    this.$refs.agile.reload()
    window.EventBus = EventBus;
    EventBus.$on('deal:reload_agile', this.reload_agile);
  },
  methods: {
    ac_short_date,
    format: require('date-fns').format,
    parse: require('date-fns').parse,
    reload_agile() {
      this.$refs.agile.reload();
      this.vue_agile_key = this.vue_agile_key + 1; //forces complete re-render of Agile
    }
  }
};
</script>
