<template>
  <input ref="inputRef" type="text" />
</template>

<script>
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: [Number, String], // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput({
      currency: "USD",
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
    });

    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        setValue(value)
      }
    )

    return { inputRef };
  },
};
</script>
