<template>
  <div v-html="wrapped_text" />
</template>

<script>
/* eslint vue/no-v-html: "off" */

export default {
  name: 'LongText',
  props: {
    row: {
      type: Object,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    ui_defs: {
      type: Object,
      required: true
    }
  },
  computed: {
    wrapped_text() {
      if(typeof this.row[this.column] != 'string'){
        return "";
      }
      let char_step;
      let wrapped = "";
      let current_chars = (char_step = 45);
      const t = this.row[this.column].split(" ");
      while (t.length > 0) {
        const word = t.shift();
        wrapped += " " + word;
        if (wrapped.length > current_chars) {
          current_chars += char_step;
          wrapped += "<br>";
        }
      }
      return wrapped;
    }
  }
};
</script>
