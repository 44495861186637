<template>
  <select v-model="act" class="select" @change="select">
    <option :value="null">
      Actions
    </option>
    <option v-if="new_distribution" value="new_capital_call">
      New Capital Call on {{ row.deal_name }}
    </option>
    <option v-if="new_distribution" value="new_distribution">
      New Distribution for {{ row.deal_name }}
    </option>
    <option v-if="deals" value="new_deal_page">
      New Details Page for {{ row.deal_name }}
    </option>
    <option v-if="deals" value="deal_pages">
      All Details Pages for {{ row.deal_name }}
    </option>
    <option v-if="deal_pages" value="delete_deal_page">
      Delete Page
    </option>
    <option v-if="deal_pages" value="duplicate_deal_page">
      Duplicate Page
    </option>
    <option v-if="deal_pages" value="publish_deal_page">
      Publish Page
    </option>
    <option v-if="has_investor" value="view_as_investor">
      Login As {{ row.investor_email }}
    </option>
    <option v-if="has_offer_id" value="docusign_dashboard">
      Launch Docusign Tests for Offer#{{ row.offer_id }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'Actions',
  props: {
    row: {
      type: Object,
      required: true
    },
    vdata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {act: null};
  },
  computed: {
    new_distribution() {
      return ["closed", "realized"].includes(this.row.deal_status_slug);
    },
    has_investor() {
      return this.row.investor_id;
    },
    has_offer_id() {
      return this.row.offer_id;
    },
    deals() {
      return this.row.deal_status;
    },
    deal_pages() {
      return this.row.deal_page_status;
    },
    text_snippet() {
      return this.row.text_snippet_id;
    }
  },
  methods: {
    select(e){
      this.vdata.controller[e.target.value].call(this, this.row);
      this.act = null;
    }
  }
};
</script>
