<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <div class="row collapse">
      <div class="column medium-6">
        <label class="input-label">{{ label }} From
          <currency-input
            v-if="config.input_type == 'currency'"
            v-model="model[model_keys[0]]"
            class="input input--radius-left"
            placeholder="From Amount"
            data-cy="filters__number-range-from"
            :data-cy-key="model_keys[0]"
          />
          <input
            v-else
            v-model="model[model_keys[0]]"
            class="input input--radius-left"
            type="number"
            placeholder="From"
            data-cy="filters__number-range-from"
            :data-cy-key="model_keys[0]"
          >
        </label>
      </div>
      <div class="column medium-6">
        <label class="input-label">{{ label }} To
          <currency-input
            v-if="config.input_type == 'currency'"
            v-model="model[model_keys[1]]"
            class="input input--radius-right"
            placeholder="To Amount"
            data-cy="filters__number-range-to"
            :data-cy-key="model_keys[1]"
          />
          <input
            v-else
            v-model="model[model_keys[1]]"
            class="input input--radius-right"
            type="number"
            placeholder="To Amount"
            data-cy="filters__number-range-to"
            :data-cy-key="model_keys[1]"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
/*
 *
 * The Number range component generates two inputs
 * if config.input_type == 'currency', the currency-input component is used,
 * otherwise they are number inputs.
 *
 */
export default {
  name: 'NumberRange',
  props: {
    label: {
      type: String,
      required: true
    },
    model_keys: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
};
</script>
