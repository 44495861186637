<template>
  <div class="subheader flex-container align-middle">
    <div class="subheader__content flex-child-grow">
      <div class="row align-middle">
        <div class="column small-6">
          <h1 v-if="heading" class="subheader__heading">
            {{ heading }}
          </h1>
          <p v-if="subheading" class="subheader__subheading">
            {{ subheading }}
          </p>
        </div>
        <div class="column small-6 text-right">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Subheader',
  props: {
    heading: {
      type:    [String, Boolean],
      default: false
    },
    subheading: {
      type:    [String, Boolean],
      default: false
    }
  }
}
</script>
