const TenDigitPhone = function(options = {}){
  if(typeof(options.numbers_only) === "undefined" ){
    options.numbers_only = false;
  }
  return function($el) {
    const $error = $el.next('span');
    const currentValue = $el.val();


    if (currentValue === '') {
      $error.text('This field is required');
      return false;
    }

    if(options.numbers_only){
      if (!currentValue.match(/^[0-9]{10}$/)) {
        $error.text('10 digits, no other characters');
        return false;
      }
    }else{
      if (currentValue.replace(/[^0-9]/g, '').length < 10) {
        $error.text('At least 10 digits required');
        return false;
      }
    }

    return true;
  };
}
export default TenDigitPhone;
