<template>
  <div class="row collapse medium-uncollapse">
    <div class="column medium-12">
      <div class="deal">
        <slot name="hero" />
        <slot name="subnav" />
        <slot name="sections" />
        <slot v-if="vdata.ui_state.edit_mode" name="modals" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DealLayout",
  props: {
    vdata: {
      type: Object,
      required: true
    }
  }
};
</script>
