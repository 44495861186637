<template>
  <thead class="table__thead">
    <tr>
      <th
        v-for="column in vdata.ui_state.active_columns"
        :key="vdata.controller.colDefs[column].label"
        :class="{table__th: true, 'table__th--sorted': vdata.ui_state.params.orderBy === column, 'table__th--cursor-default': !vdata.ui_state.sortable_columns.includes(column)}"
        @click="th_click(column)"
      >
        {{ vdata.controller.colDefs[column].label }}
        <Sorter
          v-if="vdata.ui_state.sortable_columns.includes(column)"
          :column="column"
          :order="vdata.ui_state.params.order"
          :order-by="vdata.ui_state.params.orderBy"
          :set_sort="vdata.controller.set_sort"
        />
      </th>
      <th v-if="vdata.controller.show_actions" />
    </tr>
  </thead>
</template>

<script>
import Sorter from './sorter';

export default {
  name: 'THead',
  components: {
    Sorter
  },
  props: {
    vdata: {
      type: Object,
      required: true
    }
  },
  methods: {
    th_click(column) {
      this.vdata.ui_state.sortable_columns.includes(column) ? this.vdata.controller.set_sort(column) : false
    }
  }
};
</script>
