<template>
  <div v-if="vdata.ui_state.edit_mode" class="deal__edit" :class="button_position === 'right' ? 'deal__edit--right' : 'deal__edit--left'">
    <template v-if="type === 'modal'">
      <button type="button" class="deal__edit-button button" @click="launch_editor">
        {{ button_text }}
      </button>
    </template>
    <template v-else>
      <button type="button" class="deal__edit-button button" @mouseover="toggle_menu">
        {{ button_text }}
      </button>
    </template>
    <transition name="slide-fade">
      <div v-if="show_menu" class="deal__edit-menu" @mouseleave="toggle_menu">
        <div class="row">
          <div class="column small-12 small-mb-5">
            <button type="button" class="button button--expanded button--small" @click="launch_editor">
              Edit Module
            </button>
          </div>
          <div class="column small-6 small-mb-5">
            <button type="button" class="button button--expanded button--small" @click="$emit('move_up')">
              Move Up
            </button>
          </div>
          <div class="column small-6 small-mb-5">
            <button type="button" class="button button--expanded button--small" @click="$emit('move_down')">
              Move Down
            </button>
          </div>
          <div class="column small-12 small-mb-5">
            <select v-model="module_before_choice" class="select">
              <option :value="null">
                Insert before...
              </option>
              <option v-for="(v, k) of vdata.ui_defs.module_definitions" :key="k" :value="k">
                {{ v.field_label }}
              </option>
            </select>
          </div>
          <div class="column small-12 small-mb-5">
            <select v-model="module_after_choice" class="select">
              <option :value="null">
                Insert after...
              </option>
              <option v-for="(v, k) of vdata.ui_defs.module_definitions" :key="k" :value="k">
                {{ v.field_label }}
              </option>
            </select>
          </div>
          <div class="column small-12">
            <button type="button" class="button button--expanded button--red button--small" @click="$emit('delete_module')">
              Delete
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Edit',
  props: {
    button_text: {
      type:     String,
      required: true
    },
    button_position: {
      type:     String,
      default:  'left'
    },
    type: {
      type:    String,
      default: 'menu'
    },
    vdata: {
      type:     Object,
      required: true
    },
    editor_type: {
      type: String,
      required: true
    },
    editor_target: {
      type: Object,
      required: true
    },
    editor_target_address: {
      type: Object,
      required: false,
      default: undefined
    },
    add_module_callback: {
      type: Function,
      required: false,
      default: ()=>{}
    }
  },
  data() {
    return {
      show_menu:  false,
      module_before_choice: null,
      module_after_choice: null
    }
  },
  watch: {
    module_before_choice(newval) {
      this.add_module_callback(newval, 'before');
      this.module_before_choice = null;
    },
    module_after_choice(newval) {
      this.add_module_callback(newval, 'after');
      this.module_after_choice = null;
    }
  },
  methods: {
    launch_editor() {
      this.vdata.controller.edit(this.editor_type, this.editor_target, this.editor_target_address);
    },
    toggle_menu() {
      this.show_menu = !this.show_menu;
    }
  }
};
</script>
