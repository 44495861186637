<template>
  <select class="select select--small">
    <option>{{ row.deal_status }}</option>
  </select>
</template>

<script>
export default {
  name: 'DealStatus',
  props: {
    row: {
      type: Object,
      required: true
    }
  }
};
</script>
