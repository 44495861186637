<template>
  <div class="deal__subsection">
    <select v-if="subsection.data.modules.length == 0" class="select" @change="add_first_module">
      <option :value="null">
        Add first module
      </option>
      <option v-for="(v, k) of vdata.ui_defs.module_definitions" :key="k" :value="k">
        {{ v.field_label }}
      </option>
    </select>

    <slot />
  </div>
</template>

<script>

export default {
  name: "Subsection",
  props: {
    vdata: {
      type:     Object,
      required: true
    },
    subsection: {
      type:     Object,
      required: true
    }
  },
  methods: {
    add_first_module(event) {
      this.vdata.controller.module_control('insert', this.subsection, 0, event.target.value, 'before');
    }
  }
};
</script>
