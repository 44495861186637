<template>
  <div class="filters" :class="{ 'filters--hidden': !vdata.ui_state.show_filters }">
    <div class="row is-collapse-child">
      <div class="column small-12">
        <Filters v-show="vdata.ui_state.show_filters" :vdata="vdata" data-cy="filter-panel" />
      </div>
    </div>
  </div>
</template>

<script>
import './list-view-filters';

export default {
  name: 'FilterPanel',
  props: {
    vdata: {
      type: Object,
      default: ()=> { return {}; }
    }
  },
  methods: {
    toggle_modal() {
      this.vdata.ui_state.show_saved_views_modal = !this.vdata.ui_state.show_saved_views_modal;
    }
  }
};
</script>
