<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <label class="input-label">{{ label }}</label>
    <Multiselect
      v-if="ready"
      v-model="values"
      track-by="value"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      label="label"
      data-cy="filters__multi-selector"
      placeholder="Select"
      :data-cy-key="model_keys[0]"
      @update:modelValue="handle_input"
    />
  </div>
</template>


<script>
import { Multiselect } from 'vue-multiselect';

/*
 *
 * The MultiSelector component wraps vue-multiselect and populates with values from an endpoint
 *
 */
export default {
  name: 'MultiSelector',
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    model_keys: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      options: [],
      values: []
    };
  },
  computed: {
    current_prop_value() {
      return this.model[this.model_keys[0]];
    }
  },
  watch: {
    current_prop_value() {
      this.values = this.model[this.model_keys[0]] ? (this.options.filter((opt) => this.model[this.model_keys[0]].includes(opt.value))) : [];
    }
  },
  mounted() {
    $.getJSON(this.$props.config.data_endpoint, this.receive);
  },
  methods: {
    handle_input(value){
      this.model[this.model_keys[0]] = value.map(e => e.value);
    },
    receive(resp){
      // the option values need to be strings at all times; coming from the URL params
      // they will be strings, but coming from the DB as JSON they may be Ints so we
      // force convert here
      this.$data.options = resp.options.map(function(e){
        e.value = e.value.toString();
        return e;
      });
      this.values = this.model[this.model_keys[0]] ? (resp.options.filter((opt) => this.model[this.model_keys[0]].includes(opt.value))) : [];
      this.$data.ready = true;
    }
  }
};

</script>
