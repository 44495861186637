/*
 *
 * The list view parameter filters are generated programmatically so we need
 * a JS class instead of a Vue template. The props are an array of filter definition
 * objects with structure
 *     type: Filter type (DateRange, Text, Select, etc)
 *     params: Array of references to the VueJS data params store
 *
 */
import { h } from "vue";

export default function (app) {
  app.component("Filters", { // eslint-disable-line vue/multi-word-component-names
    props: {
      vdata: {
        type: Object,
        required: true,
      },
    },
    render() {
      const filter_components = {
        BooleanSelector: require("./filters/boolean-selector.vue").default,
        DateRange: require("./filters/date-range.vue").default,
        NewTextSnippetButton: require("./filters/new-text-snippet-button.vue")
          .default,
        NumberRange: require("./filters/number-range.vue").default,
        TextSearchField: require("./filters/text-search-field.vue").default,
        MultiSelector: require("./filters/multi-selector.vue").default,
      };
      const filter_definitions = [];
      for (var def of this.$props.vdata.controller.filter_definitions_for_view()) {
        const component =
          filter_components[def.component] ||
          (() => {
            throw new Error(`${def.component} component is undefined`);
          })(), props = {
            label: def.label,
            model: this.$props.vdata.ui_state.params,
            model_keys: def.params,
            config: def.config || {},
          }
        filter_definitions.push(
          h(component, props)
        );
      }
      return h("div", { class: "row" }, filter_definitions);
    },
  });
}
