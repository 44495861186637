<template>
  <Datepicker
    v-model="model"
    :clearable="true"
    :enable-time-picker="false"
    :text-input="textInputOptions"
    :start-time="startTime"
    :utc="true"
  />
</template>

<script setup>
import Datepicker from "@vuepic/vue-datepicker";

const props = defineProps({
  typeable: {
    type: Boolean,
    default: true,
  },
});
let textInputOptions = props.typeable
  ? {
      enterSubmit: true,
      tabSubmit: true,
      openMenu: true,
    }
  : null;

const model = defineModel(),
  startTime = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
</script>
