<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <label class="input-label">{{ label }}</label>
    <button class="button button--small" data-edit-text-snippet="new">
      Create New
    </button>
  </div>
</template>


<script>
export default {
  name: 'NewTextSnippetButton',
  props: {
    label: {
      type: String,
      required: true
    },
  },
};

</script>
