<template>
  <div
    :id="unique_id"
    :class="container_class"
    :data-validate="vdata.validate"
    data-cy="upload-file__container"
  >
    <div class="upload-file__dropzone">
      <div class="upload-file__label" :class="{ 'upload-file__label--hidden': hide_dropzone_label }">
        Drop File Here<br>
        or
      </div>
      <button
        class="upload-file__button button button--primary"
        :class="button_class"
        :disabled="vdata.disabled"
        data-cy="upload-file__button"
        @click="upload_click"
      >
        {{ button_label() }}
      </button>
    </div>
  </div>
</template>

<script>
/*
 *
 * @upload-file.vue component
 * Takes a property bag bound to the 'vdata' attribute. Params:
 * @auto_upload - boolean, whether to POST immediately when files are added
 * @disabled - boolean, for the button
 * @button_class - class to apply to the button element
 * @choose_label - label for button when auto_upload is false and no file selected. Default "Choose".
 * @container_class - class to apply to the container element
 * @mime_types - an array or comma-delimited list of mime types to accept
 * @endpoint - the url to POST to
 * @oncomplete - callback for the 'complete' event, or a string to eval
 * @onsuccess - callback for the 'success' event, or a string to eval
 * @onqueuecomplete - callback for the 'queuecomplete' event, or a string to eval
 * @onsending - callback for the 'sending' event, or a string to eval
 * @upload_label - label for button when auto_upload is true or a file is selected. Default "Upload".
 * @additional_data - object of key->val pairs, appended to the form before submission. If data is
 *     dynamic and not known at instantiation time, the onsending callback can be used.
 *
 *
 *
 *
 */
import Dropzone from 'dropzone';

export default
  ({
    name: "UploadFile",
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data: function() {
      return {
        dropzone: null,
        __dropzone_id: null,
        hide_dropzone_label: false
      }
    },
    computed: {
      button_class() {
        return this.vdata.button_class || '';
      },
      container_class() {
        return this.vdata.container_class || 'upload-file';
      },
      unique_id() {
        if (this.__dropzone_id) { return this.__dropzone_id; }
        return this.set_unique_id();
      }
    },
    mounted() {
      let mime_types = this.vdata.mime_types || "application/pdf";
      let max_files = this.vdata.max_files || 1;
      if (typeof(mime_types) === "object") {
        mime_types = mime_types.join(",");
      }
      console.log("Uploader component mounted with config ", this.vdata);
      const params = Object.assign({
        authenticity_token: $("meta[name=csrf-token]").attr('content'),
        mime_types: mime_types
      }, (this.vdata.additional_data || {}));
      this.dropzone = new Dropzone("#"+this.__dropzone_id, {
        url: this.vdata.endpoint,
        acceptedFiles: mime_types,
        maxFiles: max_files,
        autoProcessQueue: !!this.vdata.auto_upload,
        addRemoveLinks: true,
        createImageThumbnails: true,
        params
      });
      if (this.vdata.onsuccess) {
        if (typeof this.vdata.onsuccess === 'string') {
          this.dropzone.on('success', eval(this.vdata.onsuccess));
        } else {
          this.dropzone.on('success', this.vdata.onsuccess);
        }
      }
      if (this.vdata.onqueuecomplete) {
        if (typeof this.vdata.onqueuecomplete === 'string') {
          this.dropzone.on('queuecomplete', eval(this.vdata.onqueuecomplete));
        } else {
          this.dropzone.on('queuecomplete', this.vdata.onqueuecomplete);
        }
      }
      if (this.vdata.oncomplete) {
        if (typeof this.vdata.oncomplete === 'string') {
          this.dropzone.on('complete', eval(this.vdata.oncomplete));
        } else {
          this.dropzone.on('complete', this.vdata.oncomplete);
        }
      }
      if (this.vdata.onsending) {
        if (typeof this.vdata.onsending === 'string') {
          this.dropzone.on('sending', eval(this.vdata.onsending));
        } else {
          this.dropzone.on('sending', this.vdata.onsending);
        }
      }
      if (this.vdata.onerror) {
        if (typeof this.vdata.onerror === 'string') {
          this.dropzone.on('error', eval(this.vdata.onerror));
        } else {
          this.dropzone.on('error', this.vdata.onerror);
        }
      }
      this.dropzone.on('addedfile', (ev) => {
        this.$forceUpdate();
        this.$emit('addedfile', ev);
      });
      this.dropzone.on('reset', () => {
        this.$forceUpdate();
      });
      this.$emit('dropone_instance', this.dropzone)
    },
    methods: {
      upload_click(e){
        if ((this.dropzone.files.length > 0) && !this.vdata.auto_upload) {
          this.dropzone.processQueue();
        } else {
          $(e.currentTarget).parents(".dz-clickable").click();
        }
      },
      button_label() {
        const f = this.dropzone ? this.dropzone.files.length : 0;
        if (!this.vdata.auto_upload && (f === 0)) {
          this.hide_dropzone_label = false;
          return this.vdata.choose_label || "Choose a File";
        } else {
          this.hide_dropzone_label = true;
          return this.vdata.upload_label || "Upload";
        }
      },
      set_unique_id() {
        this.__dropzone_id = `dropzone-${Math.random().toString().replace(/\./,'')}`;
        return this.__dropzone_id;
      }
    }
  });
</script>
