<template>
  <div class="deal__module">
    <slot />
    <h3 v-if="module.heading != ''" class="deal__subsection-heading">
      {{ module.heading }}
    </h3>
    <div :class="div_classes">
      <img :src="module.image.url" :class="img_classes">
    </div>
  </div>
</template>

<script>
export default {
  name: "FullWidthImage",
  props: {
    module: {
      type:     Object,
      required: true
    }
  },
  computed: {
    img_classes() {
      return({
        "image": true,
        "image--expanded": !this.module.native_width,
        "image--radius": true
      })
    },
    div_classes() {
      return({
        "deal__subsection-box": true,
        "box": true,
        "box--radius": true,
        "text-center": this.module.native_width
      })
    }
  }
};
</script>
