/*
 *
 * @file upload-file
 * Automatically creates Dropzone Vue app instances where they are needed outside
 * of enclosing VueJS apps.
 *
 */

import { createApp } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';

const Uploader = require('./upload-file.vue').default;

class UploadFile {

  constructor(app) {
    this.init = this.init.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
    this.instances = [];
  }

  init() {
    // Init if dropzones are present
    if (!$("[data-vue-target='upload-file']").length) {
      return;
    }
    this.instances = [];
    let instances = this.instances;
    $("[data-vue-target='upload-file']").each(function() {
      const d = $(this).data();
      const config = {
        endpoint: d.uploadUrl,
        button_class: d.buttonClass,
        container_class: d.containerClass,
        auto_upload: d.autoProcessQueue,
        mime_types: d.mimeTypes
      };
      const app = createApp(Uploader, { vdata: config });
      configureVueAppDefaults(app);
      app.mount($(this)[0]);
      instances.push(app);
      // instances.push(new Vue({
      //   el: $(this)[0],
      //   components: { Uploader },
      //   data() {
      //     return {vdata: config};
      //   },
      //   template: '<Uploader v-bind:vdata="vdata"/>'
      // }));
    });
  }
}


export default UploadFile;
