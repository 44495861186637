<template>
  <div class="deal__module">
    <slot />
    <template v-if="module.image">
      <div class="row">
        <div class="column small-12 large-6" :class="{ 'large-order-2': module.image_align == 'left' }">
          <div v-html="module.content" />
        </div>
        <div class="column small-12 large-6" :class="{ 'large-order-1': module.image_align == 'left' }">
          <div class="deal__subsection-box box box--radius">
            <img :src="module.image.url" class="image image--expanded image--radius">
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-html="module.content" />
    </template>
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */
export default {
  name: "TextBlock",
  props: {
    module: {
      type:     Object,
      required: true
    }
  }
};
</script>
