<template>
  <Modal :show="show" size="large" @close="toggle_modal">
    <template #header>
      <div class="modal__content modal__content--header">
        <h5 class="modal__heading">
          My Views
        </h5>

        <p class="modal__subtitle">
          {{ current_saved_view_name | capitalize }}
        </p>

        <Multiselect
          id="saved_view_ms"
          :options="views"
          :searchable="true"
          label="name"
          placeholder="Type to search"
          track-by="id"
          @select="load_view"
        />
      </div>
    </template>
    <template #body>
      <div class="modal__content modal__content--body modal__content--bordered modal__content--radius" :style="ui_state.show_create_view ? 'height: 158px' : ''">
        <div v-for="(oview, index) in views" :key="index" class="row align-middle">
          <div class="column small-6">
            <p>{{ oview.name }}</p>
          </div>
          <div class="column small-3">
            <button
              class="button button--small expanded"
              :class="{ 'button--secondary' : oview.name !== current_saved_view.name }"
              @click="load_view(oview)"
            >
              Select{{ oview.name === current_saved_view.name ? 'ed' : '' }}
            </button>
          </div>
          <div class="column small-3">
            <button class="button button--secondary button--small expanded" @click="remove(oview)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal__content modal__content--footer">
        <button v-if="!ui_state.show_create_view" class="button expanded" @click="toggle_create_view">
          Create New View
        </button>
        <template v-if="ui_state.show_create_view">
          <input
            ref="create_view_name"
            v-model="ui_state.create_view_name"
            class="input"
            type="text"
            @keydown.esc="toggle_create_view"
            @keyup.enter="create"
          >
          <div class="row">
            <div class="column small-12 medium-6">
              <button class="button button--secondary expanded" @click="toggle_create_view">
                Cancel
              </button>
            </div>
            <div class="column small-12 medium-6">
              <button :disabled="save_is_disabled" class="button expanded" @click="create">
                Save
              </button>
            </div>
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
import EventBus from 'shared/event-bus';
import { default as Modal } from 'shared/modal.vue';
import { Multiselect } from 'vue-multiselect';

export default {
  name: "SavedViewsModal",
  components: {
    Modal,
    Multiselect
  },
  props: {
    active_columns: {
      type:     Array,
      required: true
    },
    current_saved_view: {
      type:     [Object, Boolean],
      required: true
    },
    load_view: {
      type:     Function,
      required: true
    },
    params: {
      type:     Object,
      required: true
    },
    show: {
      type:     Boolean,
      required: true
    },
    view: {
      type:     String,
      required: true
    }
  },
  data() {
    return {
      ui_state: {
        create_view_name: '',
        show_create_view: false
      },
      views: []
    }
  },
  computed: {
    current_saved_view_name: function() {
      return this.current_saved_view ? this.current_saved_view.name : 'default';
    },
    save_is_disabled: function() {
      return this.ui_state.create_view_name.trim() ? false : true;
    }
  },
  watch: {
    'ui_state.show_create_view': function(value) {
      if (value) {
        this.$nextTick(() => {
          this.focus_input('create_view_name');
        })
      }
    }
  },
  created() {
    this.push_and_receive();
  },
  mounted: function() {
    EventBus.$on('tabular_data:update_saved_views', this.update);
    EventBus.$on('tabular_data:toggle_saved_views_modal', this.focus_multiselect);
    EventBus.$on('tabular_data:toggle_create_view', this.toggle_create_view);
  },
  beforeDestroy() {
    EventBus.$off('tabular_data:update_saved_views', this.update);
    EventBus.$off('tabular_data:toggle_saved_views_modal', this.focus_multiselect);
    EventBus.$off('tabular_data:toggle_create_view', this.toggle_create_view);
  },
  methods: {
    create() {
      if (this.save_is_disabled) return
      this.push_and_receive('create');
      this.toggle_create_view();
    },
    focus_multiselect() {
      if(!this.show){ //modal is being toggled on
        this.$nextTick(() => {
          $('#saved_view_ms').parents('.multiselect').focus();
        });
      }
    },
    focus_input(ref) {
      this.$refs[ref].focus();
    },
    parse_params(params) {
      let { page, ...parsed_params } = params;
      for (let prop in parsed_params) { const val = parsed_params[prop]; if (val === null) { delete parsed_params[prop]; } }
      return parsed_params;
    },
    push_and_receive(action, view) {

      let url = '/admin/saved_list_views';
      let index = (view ? this.views.map(view => view.id).indexOf(view.id) : '');
      let method;
      let payload;

      switch (action) {
        case 'create':
        case 'update':
          payload = {
            name: action == 'create' ? this.ui_state.create_view_name : this.current_saved_view.name,
            view: this.view,
            settings: {
              active_columns: this.active_columns,
              params:         this.parse_params(this.params)
            }
          };

          if (action == 'create') {
            method = 'POST';
          } else {
            url += `/${view.id}`
            method = 'PATCH'
          }

          break
        case 'delete':
          url += `/${view.id}`
          method = 'DELETE';
          break;
        default:
          url += `?view=${this.view}`
          method = 'GET'
      }

      $.ajax({
        url:      url,
        type:     method,
        data:     payload ? JSON.parse(JSON.stringify(payload)) : {},
        dataType: 'json',
      })
      .done((response) => {
        switch (action) {
          case 'create':
            this.views.push(response.view);
            break
          case 'delete':
            this.views.splice(index, 1);
            this.$emit('update:current_saved_view', false);
            break;
          case 'update':
            this.views.splice(index, 1, response.view);
            break;
          default:
            this.views = response;
        }
      })
      .fail(() => {
        console.error(`Saved Views: Failed to ${method}`);
      });

    },
    remove(view) {
      this.push_and_receive('delete', view);
    },
    toggle_create_view()  {
      this.ui_state.create_view_name = '';
      this.ui_state.show_create_view = !this.ui_state.show_create_view;
    },
    toggle_modal() {
      this.$emit('toggle_modal');
    },
    update() {
      this.push_and_receive('update', this.current_saved_view);
    }
  }
};

</script>

<style lang="scss" scoped>
  .modal__content {

    &--body {
      height: 212px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
</style>
