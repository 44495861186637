/*
 *
 * The cell in a tabular view can be a simple value or a complicated component.
 * This class handles instantiating them with appropriate parameters
 *
 */

import { h, markRaw } from "vue";

export default function (app) {
  app.component("BaseCell", {
    props: {
      row: {
        type: Object,
        required: true,
      },
      colDef: {
        type: Object,
        required: true,
      },
      column: {
        type: String,
        required: true,
      },
      ui_defs: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        cell_components: markRaw({
          AdminStatus: require("./admin-status.vue").default,
          AccreditationStatus: require("./document-status.vue").default,
          DealStatus: require("./deal-status.vue").default,
          EmailPreviewOpener: require("./email-preview-opener.vue").default,
          LongText: require("./long-text.vue").default,
          OfferStatus: require("./offer-status.vue").default,
          SubdocStatus: require("./document-status.vue").default,
          TransactionStatus: require("./transaction-status.vue").default,
        }),
      };
    },

    render() {
      if (this.colDef.cell) {
        // requires a custom component
        return h(this.cell_components[this.colDef.cell], {
          row: this.row,
          column: this.column,
          ui_defs: this.ui_defs,
        });
      } else {
        let value = "";
        if (this.row[this.column] != null) {
          switch (this.colDef.datatype) {
            case "text":
            case "email":
              value = this.row[this.column];
              break;
            case "boolean":
              value = this.row[this.column] ? "Yes" : "No";
              break;
            case "date":
              value = new Intl.DateTimeFormat("en-US", {
                dateStyle: "medium",
                timeZone: "UTC",
              }).format(Date.parse(this.row[this.column]));
              break;
            case "datetime":
              value = new Intl.DateTimeFormat("en-US", {
                dateStyle: "medium",
                timeStyle: "medium",
              }).format(Date.parse(this.row[this.column]));
              break;
            default:
              throw new Error(
                `Unknown datatype defined for column ${this.column}`
              );
          }
        }
        if (this.colDef.wrap) {
          for (let func of this.colDef.wrap) {
            value = func.call(this, this.row, this.column, value);
          }
        }
        return h("span", { innerHTML: value });
      }
    },
  });
}
