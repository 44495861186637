/*
 *
 * @file tabular-data
 * Provides the admin views of Offers, Deals, Entities, Users, and Admin lists.
 * There are two sources of complexity that this tabular data view must deal with.
 * The first is the polyvalent nature of the data in the cells, for example,
 * the requirement that data in some cells be hyperlinked to other views, or that
 * dateTime objects be formatted for humans.
 * The second is the fact that rows of the data are most often the result of joins
 * across models and, in some cases, cells contain data that is the result of a calculation
 * on the data after it leaves the database.
 *
 * Separation of concens:
 * Insofar as it is possible, the display logic is confined to the JS on the front
 * end. Data transformations like date formatting and linking are done by the JS.
 * However, the JS and the Rails app do need to agree on a common set of column names
 * and formats. The column names in the views don't always agree with the names in the
 * data schema, for example, the User model is represented as "Investor" in the front end.
 * Therefore, a common set of column names is used as parameters to the search, and
 * these names are chosen to match the UI rather than the database, where they diverge.
 * A column of a given name shouled always have the same definition regardless of
 * what view (Deals, Offers, etc) it participates in.
 * The front end JS has the master list of column definitions. The definitions contain
 * information on whether a transformation function needs to be applied to the cell
 * data, or whether a custom component is used to represent it in the row.
 * The back end also knows about the column names, but only enough to be able to filter
 * on them appropriately. The back end always provides the full set of columns associated
 * with a view (Deal, Offer, etc) regardless of which columns have been selected to display
 * on the front end. This is because some of these columns will be metadata (such
 * as links to edit pages for Users or Offers) that is not displayed directly but is used
 * in constructing cells.
 *
 */

// for keyboard shortcuts - https://github.com/jaywcjlove/hotkeys
import hotkeys from 'hotkeys-js';
import { createApp, reactive, watch } from "vue";
import { default as EventBus } from 'shared/event-bus';
import baseCellComponents from 'admin/components/tabular-data/cells/base.js';
import listViewFilterComponents from 'admin/components/tabular-data/list-view-filters.js';
import { configureVueAppDefaults } from 'shared/vue-configured';
import debounce from 'debounce';
const App = require('./tabular-data.vue').default;

import Turbolinks from 'turbolinks'



class TabularData {

  constructor(app, config) {
    this.clear_filters = this.clear_filters.bind(this);
    this.column_definitions = this.column_definitions.bind(this);
    this.current_search_url = this.current_search_url.bind(this);
    this.default_params = this.default_params.bind(this);
    this.delete_deal_page = this.delete_deal_page.bind(this);
    this.duplicate_deal_page = this.duplicate_deal_page.bind(this);
    this.export = this.export.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetch_failure = this.fetch_failure.bind(this);
    this.filter_definitions_for_view = this.filter_definitions_for_view.bind(this);
    this.get_payload = this.get_payload.bind(this);
    this.init = this.init.bind(this);
    this.load_view = this.load_view.bind(this);
    this.publish_deal_page = this.publish_deal_page.bind(this);
    this.receive = this.receive.bind(this);
    this.set_sort = this.set_sort.bind(this);
    this.text_snippet_edit = this.text_snippet_edit.bind(this);
    this.text_snippet_close = this.text_snippet_close.bind(this);
    this.toggle_filters = this.toggle_filters.bind(this);
    this.toggle_change_view_modal = this.toggle_change_view_modal.bind(this);
    this.toggle_saved_views_modal = this.toggle_saved_views_modal.bind(this);
    this.update_document_status = this.update_document_status.bind(this);
    this.update_text_search = this.update_text_search.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
    this.default_quill_options = config.default_quill_options;

    $(document)
      .on('click', '[data-edit-text-snippet]', this.text_snippet_edit)
    hotkeys('alt+x', this.clear_filters);
    hotkeys('alt+f', this.toggle_filters);
    hotkeys('alt+v', (e)=> {
      e.preventDefault();
      EventBus.$emit('tabular_data:toggle_saved_views_modal');
    })
    hotkeys('alt+s', (e)=> {
      e.preventDefault();
      EventBus.$emit('tabular_data:toggle_saved_views_modal');
      setTimeout( ()=> EventBus.$emit('tabular_data:toggle_create_view'));
    })
    EventBus.$on('update-document-status', this.update_document_status);
    EventBus.$on('tabular_data:request_csv', this.export);
    EventBus.$on('tabular_data:toggle_saved_views_modal', this.toggle_saved_views_modal);
    EventBus.$on('tabular_data:toggle_change_view_modal', this.toggle_change_view_modal);
    EventBus.$on('tabular_data:toggle_filters', this.toggle_filters);
    EventBus.$on('tabular_data:save_snippet', this.fetch);
  }

  init() {
    // Check to see if we are on the subnav page
    if (!$("[data-vue-target=tabular-data]").length) {
      return;
    }
    console.log("TabularData:init");
    const base_data = window.vue_config;
    const params = this.default_params(base_data.ui_state.params);
    base_data.ui_state = Object.assign({}, this.default_ui_state(), base_data.ui_state);
    base_data.ui_state.params = params;
    base_data.controller = this;
    base_data.default_quill_options = this.default_quill_options;
    base_data.result = this.default_result();
    this.view = base_data.view; // the principal model being queried
    this.show_actions = ["deals", "deal_pages", "offers", "users", "email_templates"].includes(base_data.view);
    this.fetching = false;
    this.colDefs = this.column_definitions();
    const vdata = reactive(base_data);
    this.vm = createApp(App, { vdata })
    this.vm.vdata = vdata; // patch to accommodate legacy vue 2 references
    configureVueAppDefaults(this.vm);
    baseCellComponents(this.vm);
    listViewFilterComponents(this.vm);
    this.vm.mount("[data-vue-target=tabular-data]");
    // this.vm = new Vue({
    //   el: '[data-vue-target=tabular-data]',
    //   components: { App },
    //   data() {
    //     return {vdata: base_data};
    //   },
    //   template: '<App v-bind:vdata="vdata"/>'
    // });
    watch(this.vm.vdata.ui_state.params, debounce(this.fetch, 300), {deep: true});
    // this.vm.$watch("vdata.ui_state.params", debounce(this.fetch, 300), {deep: true});
    this.fetch();

    window._vm = this.vm;

  }

  clear_filters() {
    const new_params = {
      order: this.vm.vdata.ui_state.params.order,
      orderBy: this.vm.vdata.ui_state.params.orderBy
    }
    this.vm.vdata.ui_state.params= this.default_params(new_params);
    this.vm.vdata.ui_state.current_saved_view = false;
  }

  default_ui_state() {
    return {
      current_saved_view:     false,
      show_change_view_modal: false,
      show_filters:           true,
      show_saved_views_modal: false,
      text_snippet:           false
    };
  }


  default_result() {
    return {rows: []};
  }

  default_params(params) {
    const defaults = {
      page: 1,
      pp: 25
    };
    params = Object.assign(defaults, params);
    // we have to convert params that will be managed by NumberRange to floats from strings
    // or the filter components complain
    const nm = [];
    const object = this.filter_definitions();
    for (let key in object) {
      const filter = object[key];
      if (filter.component === "NumberRange") {
        nm.push(filter.params);
      }
    }
    for (let param_pair of nm) {
      for (let p of param_pair) {
        if (typeof params[p] === "string") {
          params[p] = parseFloat(params[p]);
        }
      }
    }
    return params;
  }

  /*
  *
  * Incoming saved views are validated here in case the DB schema has changed
  * since the view was saved.
  *
  */
  load_view(view){
    let active_columns = [];
    for (let column of view.settings.active_columns){
      if(this.vm.vdata.ui_state.columns.includes(column)){
        active_columns.push(column);
      }
    }
    this.vm.vdata.ui_state.active_columns = active_columns;

    const allowed_params = Object.keys(this.vm.vdata.ui_state.params);
    for (let key in view.settings.params){
      if(allowed_params.includes(key)){
        this.vm.vdata.ui_state.params[key] = view.settings.params[key]
      }
    }

    this.vm.vdata.ui_state.current_saved_view = view
    this.vm.vdata.ui_state.show_saved_views_modal = false
  }


  /*
   *
   * Column defs for the UI
   * label: displayed to user
   * datatype: used in the t-body component for formatting
   * cell: optional, defines a vue component to render instead of the value
   * wrap: optional array of functions to call on the value before display
   *
   */
  column_definitions() {
    return {
      admin_created_at: {
        label: "Date",
        datatype: "date"
      },
      admin_name: {
        label: "Name",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_admin_url")
        ]
      },
      admin_email: {
        label: "Email",
        datatype: "email",
        wrap: [
          this.mailto
        ]
      },
      admin_phone: {
        label: "Phone",
        datatype: "text",
        wrap: [
          this.admin_tel
        ]
      },
      admin_role: {
        label: "Role",
        datatype: "text"
      },
      admin_group: {
        label: "Group",
        datatype: "text"
      },
      admin_last_login_at: {
        label: "Last Login",
        datatype: "date"
      },
      admin_active: {
        label: "Status",
        datatype: "text",
        cell: "AdminStatus"
      },
      accreditation_status: {
        label: "Accreditation",
        datatype: "text",
        cell: "AccreditationStatus"
      },
      accreditation_expiration_date: {
        label: "Accreditation Expiration Date",
        datatype: "date"
      },
      build_type: {
        label: "Build Type",
        datatype: "text"
      },
      date_closed: {
        label: "Date Closed",
        datatype: "date"
      },
      date_joined: {
        label: "Date Joined",
        datatype: "date"
      },
      date_launched: {
        label: "Date Launched",
        datatype: "date"
      },
      date_realized: {
        label: "Date Realized",
        datatype: "date"
      },
      date_submitted: {
        label: "Submitted",
        datatype: "date"
      },
      deal_asset_class: {
        label: "Property Type",
        datatype: "text"
      },
      deal_fell_out_offers: {
        label: "# Fell Out",
        datatype: "text"
      },
      deal_display_location: {
        label: "Location",
        datatype: "text"
      },
      deal_registration_type: {
        label: "Deal Registration Type",
        datatype: "text"
      },
      deal_name: {
        label: "Deal",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_admin_deal_path")
        ]
      },
      deal_number_of_investors: {
        label: "# of Invs.",
        datatype: "text"
      },
      deal_number_of_signatories: {
        label: "# of Sigs.",
        datatype: "text"
      },
      deal_number_of_members: {
        label: "# of Members",
        datatype: "text"
      },
      deal_page_id: {
        label: "Page ID",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_admin_deal_page_path")
        ]
      },
      deal_page_status: {
        label: "Page Status",
        datatype: "text"
      },
      deal_page_current_editor: {
        label: "Being Edited By",
        datatype: "text"
      },
      deal_page_published_at: {
        label: "Published At",
        datatype: "datetime"
      },
      deal_page_archived_at: {
        label: "Archived At",
        datatype: "datetime"
      },
      deal_page_updated_at: {
        label: "Last Updated",
        datatype: "datetime"
      },
      deal_rejected_offers: {
        label: "# Rejected",
        datatype: "text"
      },
      deal_waitlisted_offers: {
        label: "# Waitlisted",
        datatype: "text"
      },
      deal_status: {
        label: "Status",
        datatype: "text"
      },
      email_template_name: {
        label: "Template Name",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_email_template_url")
        ]
      },
      email_template_created_at: {
        label: "Created At",
        datatype: "date"
      },
      email_template_is_system: {
        label: "System?",
        datatype: "text",
        wrap: [
          this.yes_no
        ]
      },
      email_to: {
        label: "Sent to",
        datatype: "text",
        cell: "EmailPreviewOpener"
      },
      email_subject: {
        label: "Subject",
        datatype: "text"
      },
      email_delivered_at: {
        label: "Sent At",
        datatype: "datetime"
      },
      email_batch: {
        label: "Batch",
        datatype: "text"
      },
      email_last_event: {
        label: "Last Status",
        datatype: "text"
      },
      email_last_event_at: {
        label: "Status Updated",
        datatype: "datetime"
      },
      email_sent: {
        label: "Sent to",
        datatype: "boolean"
      },
      entity_type: {
        label: "Entity Type",
        datatype: "text"
      },
      entity_date_created: {
        label: "Date Created",
        datatype: "date"
      },
      entity_name: {
        label: "Entity Name",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_entity_url")
        ]
      },
      entity_investment_count: {
        label: "Investments",
        datatype: "text"
      },
      entity_investments_total: {
        label: "Total Invested",
        datatype: "text",
        wrap: [ this.usd ]
      },
      entity_signatory_count: {
        label: "Signatories",
        datatype: "text"
      },
      entity_manager: {
        label: "Manager",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_investor_url")
        ]
      },
      entity_manager_phone: {
        label: "Phone",
        datatype: "text"
      },
      entity_manager_email: {
        label: "Email",
        datatype: "email",
        wrap: [
          this.mailto
        ]
      },
      entity_member_count: {
        label: "Members",
        datatype: "text"
      },
      event_type: {
        label: "Event Type",
        datatype: "text"
      },
      event_message: {
        label: "Event Message",
        datatype: "text",
        cell: "LongText"
      },
      event_user_email: {
        label: "Linked User Email",
        datatype: "text",
        wrap: [
          this.mailto
        ]
      },
      event_user_name: {
        label: "Linked User",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_investor_url")
        ]
      },
      event_admin_email: {
        label: "Linked Admin Email",
        datatype: "text",
        wrap: [
          this.mailto
        ]
      },
      event_admin_name: {
        label: "Linked Admin",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_admin_url")
        ]
      },
      event_referenced_object: {
        label: "Reference",
        datatype: "text",
        wrap: [
          this.link.bind(this, "referenced_object_link")
        ]
      },
      event_created_at: {
        label: "Event Time",
        datatype: "datetime"
      },
      event_metadata: {
        label: "Metadata",
        datatype: "text"
      },
      investor_name: {
        label: "Investor",
        datatype: "text",
        wrap: [
          this.link.bind(this, "edit_investor_url")
        ]
      },
      investor_phone: {
        label: "Primary Phone",
        datatype: "text"
      },
      investor_email: {
        label: "Email",
        datatype: "email",
        wrap: [
          this.mailto
        ]
      },
      investor_state: {
        label: "State",
        datatype: "text"
      },
      investor_entity_count: {
        label: "Entity count",
        datatype: "text"
      },
      investor_last_sign_in_at: {
        label: "Last Sign-in",
        datatype: "datetime"
      },
      investor_investment_count: {
        label: "Investments",
        datatype: "text"
      },
      investor_sign_in_count: {
        label: "Sign-in Count",
        datatype: "text"
      },
      investor_investments_total: {
        label: "Total Invested",
        datatype: "text",
        wrap: [ this.usd ]
      },
      is_1031_eligible: {
        label: "Is 1031 Eligible",
        datatype: "text"
      },
      is_opportunity_zone_eligible: {
        label: "Is Opportunity Zone Eligible",
        datatype: "text"
      },
      minimum_offer: {
        label: "Minimum Offer",
        datatype: "text",
        wrap: [ this.usd ]
      },
      next_step: {
        label: "Next Step",
        datatype: "text"
      },
      number_of_buildings: {
        label: "Number of Buildings",
        datatype: "text"
      },
      number_of_units: {
        label: "Number of Units",
        datatype: "text"
      },
      offer_amount: {
        label: "Offer",
        datatype: "text",
        wrap: [
          this.usd,
          this.link.bind(this, "edit_offer_url")
        ]
      },
      offer_is_waitlisted: {
        label: "Waitlisted",
        datatype: "boolean"
      },
      offer_last_updated: {
        label: "Last Updated",
        datatype: "date"
      },
      offer_status: {
        label: "Offer Status",
        datatype: "text",
        cell: "OfferStatus"
      },
      offer_risk: {
        label: "Offer Risk",
        datatype: "text"
      },
      recent_activity: {
        label: "Recent Activity",
        datatype: "text",
        cell: "LongText"
      },
      realized_investment_period_months: {
        label: "Realized Investment Period (months)",
        datatype: "text"
      },
      realized_irr_pct: {
        label: "Realized Irr Pct",
        datatype: "text"
      },
      realized_net_equity_multiple: {
        label: "Realized Net Equity Multiple",
        datatype: "text"
      },
      self_accreditation: {
        label: "Self Accreditation",
        datatype: "text"
      },
      subdoc_status: {
        label: "Sub Docs",
        datatype: "text",
        cell: "SubdocStatus"
      },
      step_owner: {
        label: "Step Owner",
        datatype: "text"
      },
      targeted_avg_cash_yield: {
        label: "Targeted Avg Cash Yield",
        datatype: "text"
      },
      targeted_irr_pct_max: {
        label: "Targeted Irr Max Pct",
        datatype: "text"
      },
      targeted_irr_pct_min: {
        label: "Targeted Irr Min Pct",
        datatype: "text"
      },
      targeted_investment_period_years_max: {
        label: "Targeted Investment Period Years Max",
        datatype: "text"
      },
      targeted_investment_period_years_min: {
        label: "Targeted Investment Period Years Min",
        datatype: "text"
      },
      targeted_net_equity_multiple_max: {
        label: "Targeted Net Equity Multiple Max",
        datatype: "text"
      },
      targeted_net_equity_multiple_min: {
        label: "Targeted Net Equity Multiple Min",
        datatype: "text"
      },
      text_snippet_editor_type: {
        label: "Type",
        datatype: "text"
      },
      text_snippet_content: {
        label: "Content",
        datatype: "text"
      },
      text_snippet_key: {
        label: "Key",
        datatype: "text",
        wrap: [
          this.data_link.bind(this, "edit-text-snippet", "text_snippet_id")
        ]
      },
      total_ac_raise: {
        label: "AC Raise",
        datatype: "text",
        wrap: [ this.usd ]
      },
      total_capitalization: {
        label: "Total Cap.",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_amount: {
        label: "Total",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_date: {
        label: "Date",
        datatype: "date"
      },
      transaction_equity_percentage: {
        label: "Equity %",
        datatype: "text"
      },
      transaction_profit: {
        label: "Return On Capital",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_group_date: {
        label: "Date",
        datatype: "date"
      },
      transaction_group_amount: {
        label: "Amount",
        datatype: "text",
        wrap: [
          this.usd,
          this.link.bind(this, "edit_distribution_url")
        ]
      },
      transaction_return_of_capital: {
        label: "Return Of Capital",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_refund: {
        label: "Refunded",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_initial_investment: {
        label: "Initial Investment",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_capital_call: {
        label: "Capital Call",
        datatype: "text",
        wrap: [ this.usd ]
      },
      transaction_status: {
        label: "Status",
        datatype: "text"
      },
      verification: {
        label: "Verification",
        datatype: "text"
      }
    };
  }

  filter_definitions() {
    return {
      AccreditationStatus: {
        label: "Accreditation Status",
        component: "MultiSelector",
        params: ["accreditation_status"],
        config: {
          data_endpoint: "/admin/ui_filter_options/accreditation_status",
        }
      },
      AccreditationExpirationDate: {
        label: "Accred. Exp. Date",
        component: "DateRange",
        params: ["accreditation_expiration_date_from", "accreditation_expiration_date_to"]
      },
      DateSubmitted: {
        label: "Submitted",
        component: "DateRange",
        params: ["date_submitted_from", "date_submitted_to"]
      },
      Deal: {
        label: "Deal",
        component: "MultiSelector",
        params: ["deal_id"],
        config: {
          data_endpoint: "/admin/ui_filter_options/deal"
        }
      },
      DealInvestedIn: {
        label: "Invested In",
        component: "MultiSelector",
        params: ["deal_invested_in"],
        config: {
          data_endpoint: "/admin/ui_filter_options/deal"
        }
      },
      DealStatus: {
        label: "Deal Status",
        component: "MultiSelector",
        params: ["deal_status"],
        config: {
          data_endpoint: "/admin/ui_filter_options/deal_status"
        }
      },
      EventCreatedAtDate: {
        label: "Event Time",
        component: "DateRange",
        params: ["event_created_at_from", "event_created_at_to"]
      },
      EntityDateCreated: {
        label: "Date Created",
        component: "DateRange",
        params: ["entity_date_created_from", "entity_date_created_to"]
      },
      EntityInvestmentsTotal: {
        label: "Investments Total",
        component: "NumberRange",
        params: ["entity_investments_total_from", "entity_investments_total_to"],
        config: {
          input_type: 'currency'
        }
      },
      EntityType: {
        label: "Entity Type",
        component: "MultiSelector",
        params: ["entity_type"],
        config: {
          data_endpoint: "/admin/ui_filter_options/entity_type"
        }
      },
      EmailTemplateName: {
        label: "Name Contains",
        component: "TextSearchField",
        params: ["email_template_name"]
      },
      EmailTo: {
        label: "Sent to",
        component: "TextSearchField",
        params: ["email_to"]
      },
      EmailBatch: {
        label: "Batch",
        component: "TextSearchField",
        params: ["email_batch"]
      },
      EmailDeliveredAt: {
        label: "Date Delivered",
        component: "DateRange",
        params: ["email_delivered_at_from", "email_delivered_at_to"]
      },
      EventType: {
        label: "Event Type Contains",
        component: "TextSearchField",
        params: ["event_type"]
      },
      InvestorDateJoined: {
        label: "Date Joined",
        component: "DateRange",
        params: ["date_joined_from", "date_joined_to"]
      },
      InvestorLastSignInAt: {
        label: "Last Sign-in",
        component: "DateRange",
        params: ["investor_last_sign_in_at_from", "investor_last_sign_in_at_to"]
      },
      InvestorInvestmentsTotal: {
        label: "Investments Total",
        component: "NumberRange",
        params: ["investor_investments_total_from", "investor_investments_total_to"],
        config: {
          input_type: 'currency'
        }
      },
      InvestorNumberOfInvestments: {
        label: "# of Investments",
        component: "NumberRange",
        params: ["investor_investment_count_from", "investor_investment_count_to"],
        config: {
          input_type: 'number'
        }
      },
      InvestorSignInCount: {
        label: "Sign-in Count",
        component: "NumberRange",
        params: ["investor_sign_in_count_from", "investor_sign_in_count_to"],
        config: {
          input_type: 'number'
        }
      },
      NewTextSnippetButton: {
        label: "New Snippet",
        component: "NewTextSnippetButton"
      },
      OfferAmount: {
        label: "Offer Amount",
        component: "NumberRange",
        params: ["offer_amount_from", "offer_amount_to"],
        config: {
          input_type: 'currency'
        }
      },
      OfferIsWaitlisted: {
        label: "Waitlisted?",
        component: "BooleanSelector",
        params: ["offer_is_waitlisted"],
        config: {}
      },
      OfferRisk: {
        label: "Offer Risk",
        component: "MultiSelector",
        params: ["offer_risk"],
        config: {
          data_endpoint: "/admin/ui_filter_options/offer_risk",
        }
      },
      OfferStatus: {
        label: "Offer Status",
        component: "MultiSelector",
        params: ["offer_status"],
        config: {
          data_endpoint: "/admin/ui_filter_options/offer_status",
        }
      },
      SubdocStatus: {
        label: "Subdocs",
        component: "MultiSelector",
        params: ["subdoc_status"],
        config: {
          data_endpoint: "/admin/ui_filter_options/subdoc_status",
        }
      },
      TextSearch: {
        label: "Search",
        component: "TextSearchField",
        params: ["text"]
      },
      TextSnippetContent: {
        label: "Text Snippet Contains",
        component: "TextSearchField",
        params: ["text_snippet_content"]
      },
      TransactionAmount: {
        label: "Trans. Total",
        component: "NumberRange",
        params: ["transaction_amount_from", "transaction_amount_to"],
        config: {
          input_type: 'currency'
        }
      },
      TransactionDate: {
        label: "Trans. Date",
        component: "DateRange",
        params: ["transaction_date_from", "transaction_date_to"]
      },
      TransactionGroupDate: {
        label: "Date",
        component: "DateRange",
        params: ["transaction_group_date_from", "transaction_group_date_to"]
      },
      TransactionGroupAmount: {
        label: "Amount",
        component: "NumberRange",
        params: ["transaction_group_amount_from", "transaction_group_amount_to"],
        config: {
          input_type: 'currency'
        }
      },
      TransactionStatus: {
        label: "Transaction Status",
        component: "MultiSelector",
        params: ["transaction_status"],
        config: {
          data_endpoint: "/admin/ui_filter_options/transaction_status",
        }
      },
      TransactionType: {
        label: "Type",
        component: "MultiSelector",
        params: ["transaction_type"],
        config: {
          data_endpoint: "/admin/ui_filter_options/transaction_type",
        }
      },
      Verification: {
        label: "Verification",
        component: "MultiSelector",
        params: ["verification"],
        config: {
          data_endpoint: "/admin/ui_filter_options/verification",
        }
      }
    };
  }

  filter_definitions_for_view() {
    const d = this.filter_definitions();
    switch (this.view) {
      case "activity_log_events":
        return [
          d.EventType,
          d.EventCreatedAtDate
        ];
      case "distributions":
      case "capital_calls":
        return [
          d.Deal,
          d.TransactionGroupDate,
          d.TransactionGroupAmount
        ];
      case "entities":
        return [
          d.TextSearch,
          d.DealInvestedIn,
          d.EntityType,
          d.EntityDateCreated,
          d.EntityInvestmentsTotal,
          d.AccreditationStatus,
          d.AccreditationExpirationDate,
          d.Verification
        ];
      case "email_templates":
        return [
          d.EmailTemplateName
        ];
      case "offers":
        return [
          d.TextSearch,
          d.DateSubmitted,
          d.OfferAmount,
          d.OfferStatus,
          d.OfferIsWaitlisted,
          d.SubdocStatus,
          d.AccreditationStatus,
          d.Deal,
          d.EntityType,
          d.OfferRisk,
          d.AccreditationExpirationDate
        ];
      case "investments":
        return [
          d.TextSearch,
          d.DateSubmitted,
          d.OfferAmount,
          d.SubdocStatus,
          d.AccreditationStatus,
          d.Deal,
          d.EntityType,
          d.OfferRisk,
          d.AccreditationExpirationDate
        ];
      case "sent_emails":
        return [
          d.EmailTo,
          d.EmailBatch,
          d.EmailDeliveredAt
        ]
      case "transactions":
        return [
          d.TextSearch,
          d.Deal,
          d.OfferAmount,
          d.TransactionDate,
          d.TransactionType,
          d.TransactionAmount,
          d.TransactionStatus
        ];
      case "text_snippets":
        return [
          d.TextSnippetContent,
          d.NewTextSnippetButton
        ];
      case "users":
        return [
          d.TextSearch,
          d.DealInvestedIn,
          d.InvestorDateJoined,
          d.InvestorNumberOfInvestments,
          d.InvestorInvestmentsTotal,
          d.InvestorLastSignInAt,
          d.InvestorSignInCount
        ];
      case "admins":
        return [
          d.TextSearch
        ];
      default:
        return [];
    }
  }

  // This is passed to the sort controls in each column header
  set_sort(column) {
    let p = this.vm.vdata.ui_state.params
    if(p.orderBy == column){
      if(p.order == "desc"){
        p.order = "asc"
      }else{
        p.order = "desc"
      }
    }else{
      p.orderBy = column
    }
  }

  toggle_filters() {
    this.vm.vdata.ui_state.show_filters = !this.vm.vdata.ui_state.show_filters;
  }

  toggle_saved_views_modal() {
    this.vm.vdata.ui_state["show_saved_views_modal"] = !this.vm.vdata.ui_state["show_saved_views_modal"]
  }

  toggle_change_view_modal() {
    this.vm.vdata.ui_state["show_change_view_modal"] = !this.vm.vdata.ui_state["show_change_view_modal"]
  }

  text_snippet_edit(evt) {
    this.vm.vdata.ui_state.text_snippet = $(evt.currentTarget).data('editTextSnippet')
  }

  text_snippet_close() {
    this.vm.vdata.ui_state.text_snippet = false;
  }

  update_text_search() {
    this.fetch();
  }

  /*
   *
   * Wrapping functions for table cells start
   *
   * All wrapping functions take as final arguments row, column, value, and return modified value
   */
  data_link(attr, target, row, column, value) {
    if (row[target]) {
      return `<a data-${attr}='${row[target]}'>${value}</a>`;
    } else {
      return value;
    }
  }
  link(target, row, column, value) {
    if (row[target]) {
      return `<a href='${row[target]}'>${value}</a>`;
    } else {
      return value;
    }
  }

  mailto(row, column, value) {
    return `<a href='mailto:${row[column]}'>${value}</a>`;
  }

  admin_tel(row, column, value) {
    return `<a href='tel:${row.admin_phone}'>${value}</a>`;
  }

  usd(row, column, value) {
    if (value == null) { value = 0; }
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
  }

  yes_no(row, column, value) {
    if (value) { return "Yes"; } else { return "No"; }
  }


  /*
   *
   * Model-specific actions
   *
   */

  deal_pages(row) {
     window.location.href = `${window.vue_config.ajax_url}/${row.id}/deal_pages/`;
  }
  docusign_dashboard(row) {
    window.open(`${window.vue_config.ajax_url}/${row.id}/docusign_dashboard`);
  }
  new_capital_call(row) {
    window.location.href = `${window.vue_config.ajax_url}/${row.id}/capital_calls/new`;
  }
  new_deal_page(row) {
    window.location.href = `${window.vue_config.ajax_url}/${row.id}/deal_pages/new`;
  }
  new_distribution(row) {
    window.location.href = `${window.vue_config.ajax_url}/${row.id}/distributions/new`;
  }
  publish_deal_page(row) {
    if(row["deal_page_status"] == "published"){
      alert("Page is already published");
      return;
    }
    $.post(`${window.vue_config.ajax_url}/${row.id}/status`, {status: "published"}, (function(resp) {
      if(resp.status === "OK"){
        alert("Page published");
        this.fetch();
      }else{
        alert(resp.message);
      }
    }).bind(this));
  }
  delete_deal_page(row) {
    if(confirm("Delete this page?")){
      $.post(`${window.vue_config.ajax_url}/${row.id}/status`, {status: "deleted"}, (function(resp) {
        if(resp.status === "OK"){
          alert("Page deleted");
          this.fetch();
        }else{
          alert(resp.message);
        }
      }).bind(this));
    }
  }
  duplicate_deal_page(row) {
    $.post(`${window.vue_config.ajax_url}/${row.id}/clone`, {}, (function(resp) {
      if(resp.status === "OK"){
        alert("Page cloned");
        this.fetch();
      }else{
        alert(resp.message);
      }
    }).bind(this));
  }
  view_as_investor(row) {
    let new_window = window.open('');
    const investor_id = row.investor_id ? row.investor_id : row.id;
    $.getJSON(`/admin/users/${investor_id}/masquerade`, function(resp) {
      new_window.location = resp.redirect_to;
    });
  }

  /*
   *
   * AJAX functions start
   *
   */

  current_search_url(format) {
    if (format == null) { format = ""; }
    let params = $.param(this.get_payload());
    if(this.vm.vdata.ui_state.current_saved_view && format === "csv"){
      params += `&filename=${encodeURIComponent(this.vm.vdata.ui_state.current_saved_view.name)}`
    }
    return this.vm.vdata.ajax_url + format + '?' + params;
  }

  fetch() {
    if (this.fetching) {
      this.refetch = true;
      return;
    }
    this.fetching = true;
    return new Promise((resolve, reject) => {
      // prepare the payload by converting the needed attributes to a plain JS object
      const payload = this.get_payload();
      const current_search_url = this.current_search_url();
      this.update_history_if_necessary(current_search_url);

      $.ajax({
        url: this.vm.vdata.ajax_url,
        type: "GET",
        data: payload,
        dataType: 'json',
        success: this.receive.bind(this, resolve, reject),
        error: this.fetch_failure.bind(this, reject)
      });
    });
  }


  // Resends the request represented by the current params to the csv-formatted endpoint
  export() {
    EventBus.$emit('ajax_modal:open', this.current_search_url(".csv"))
  }

  get_payload() {
    const payload = JSON.parse(JSON.stringify(this.vm.vdata.ui_state.params));
    for (let prop in payload) { const val = payload[prop]; if (val === null) { delete payload[prop]; } }
    return payload;
  }

  // The payload that comes back from the server has structure:
  // result
  //    rows
  //    total
  // errors: []
  receive(resolve, reject, response) {
    this.fetching = false;
    if (this.refetch) {
      this.refetch = false;
      this.fetch();
    }
    this.vm.vdata.result = response.result;
    resolve(response);
  }


  fetch_failure(reject) {
    this.fetching = false;
    alert('fail!');
    reject();
  }

  update_document_status(doctype, offer_id, model_id, status, data) {
    const payload = JSON.parse(JSON.stringify(data));
    payload.document_type = doctype;
    payload.status = status;
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/admin/offers/${offer_id}/documents/${model_id}`,
        type: "PATCH",
        data: payload,
        dataType: 'json',
        success: resolve,
        error: this.fetch_failure.bind(this, reject)
      });
  }).then(() => {
      this.fetch();
    });
  }

  /*
  *
  * We need to replace the URL in the history with the full search if the user has landed
  * on a tabular data url without search terms. However if this is a new search we need
  * to push the URL onto the history, not replace.
  *
  */
  update_history_if_necessary(url){
    const current_url = window.location.pathname + window.location.search;
    // take no action if the url is the same
    if(url === current_url){
      console.log("Fetch: No update to history");
      return;
    }
    // if we are just adding terms to a default url, use the replace action
    if(url.split("?")[0] === window.location.pathname && window.location.search === ""){
        console.log("Fetch: Replace history");
        Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(this.current_search_url(), Turbolinks.uuid());
        return;
    }
    // or else, add this to the history list
    console.log("Fetch: Push to history");
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(this.current_search_url(), Turbolinks.uuid());
  }
}







export default TabularData;
