<template>
  <ConfirmStatusSelect
    v-if="current_value"
    :status_labels="document_status_labels"
    :target_object_type="document_type + ' document'"
    :target_object="row"
    :model_id="model_id"
    :object_type="document_type"
    :target_attribute="column"
    :on_status_change="status_update"
  />
</template>

<script>
import { default as ConfirmStatusSelect } from 'admin/components/status-confirm-select.vue';
import EventBus from 'shared/event-bus';

export default {
  name: 'DocumentStatus',
  components: {
    ConfirmStatusSelect
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    ui_defs: {
      type: Object,
      required: true
    }
  },
  data() {
    return {current_value: this.$props.row[this.$props.column]};
  },
  computed: {
    document_type() {
      switch (this.column) {
        case 'accreditation_status':
          return 'accreditation';
        case 'subdoc_status':
          return 'subscription';
        default: throw "Unable to determine document type";
      }
    },
    document_status_labels(){
      return({
        accreditation: this.ui_defs.accreditation_status_labels,
        subscription: this.ui_defs.subscription_document_status_labels
      });
    },
    model_id() {
      switch (this.column) {
        case 'accreditation_status':
          return this.$props.row.accreditation_id;
        case 'subdoc_status':
          return this.$props.row.subdoc_id;
        default: throw "unable to determine model_id";
      }
    }
  },

  methods: {
    status_update(doctype, model_id, status, data) {
      switch (this.document_type) {
        case 'accreditation': case 'subscription':
          EventBus.$emit('update-document-status', doctype, this.row.offer_id, model_id, status, data);
        break;
        default: throw "Unknown doctype";
      }
    }
  }
};

</script>
