<template>
  <Modal
    :show="show"
    :size="size"
    data-cy="notice-modal"
    @close="callback"
  >
    <template #body>
      <div data-cy="notice-modal__text" v-html="notice" />
    </template>
    <template #footer>
      <button
        type="button"
        class="button button--expanded-half"
        data-cy="notice-modal__close"
        @click="callback"
      >
        OK
      </button>
    </template>
  </Modal>
</template>

<script>
/* eslint vue/no-v-html: "off" */

import { default as Modal } from './modal.vue';
export default
  ({
    name: 'NoticeModal',
    components: {
      Modal
    },
    props: {
      notice:   {
        type: String,
        required: true
      },
      show: Boolean,
      size: {
        type: String,
        default: "large"
      },
      callback: {
        type: Function,
        required: true
      }
    }
  });
</script>
