<template>
  <div class="deal__subnav-wrapper" :style="{ height: wrapper_height }">
    <!-- <Intersect
      :root-margin="intersect_config.root_margin"
      :threshold="intersect_config.threshold"
      @enter="handle_intersect"
      @leave="handle_intersect"
    > -->
      <div />
    <!-- </Intersect> -->
    <div
      ref="subnav"
      class="deal__subnav"
      :class="{ 'deal__subnav--is-sticky': is_sticky }"
      @click="toggle_content"
    >
      <Edit
        button_text="Edit Sections"
        type="modal"
        :vdata="vdata"
        editor_type="section_list"
        :editor_target="vdata.details"
        :editor_target_address="{field: 'sections'}"
      >
        Modal Content
      </Edit>
      <div data-deal__subnav-selected class="hide-for-large">
        {{ vdata.ui_state.current_section }}
      </div>
      <div class="deal__subnav-content" :class="{ 'deal__subnav-content--is-visible': content_is_visible }">
        <div class="deal__subnav-header">
          <div class="row collapse align-justify align-middle">
            <div class="column shrink">
              <span class="deal__subnav-header_subnav_deal-name">{{ vdata.details.hero_data.subnav_title }}</span>
            </div>
            <div class="column text-right">
              <InvestCTA :vdata="vdata" :hide_new_offer_link="true" :show_investment_notice="false" />
            </div>
          </div>
        </div>
        <ul class="deal__subnav-list">
          <li v-for="(section, index) in vdata.details.sections" :key="`section${index}`" class="deal__subnav-list-item">
            <a
              :class="{ 'deal__subnav-link--active': section.label == vdata.ui_state.current_section }"
              :data-text="section.label"
              :href="link_href(section.label)"
              class="deal__subnav-link"
              @click.prevent="handle_click(index, $event)"
            >
              {{ section.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { default as Edit } from './edit.vue';
// import Intersect from 'vue-intersect';
import InvestCTA from './invest-cta.vue';

export default {
  name: "Subnav",
  components: {
    Edit,
    // Intersect,
    InvestCTA
  },
  props: {
    vdata: {
      type:     Object,
      required: true
    }
  },
  data() {
    return {
      content_is_visible: false,
      intersect_config: {
        root_margin: '0px 0px -50%',
        threshold:   [0, 1]
      },
      is_sticky:          false,
      link_index:         0,
      media_breakpoint:   window.matchMedia('(min-width: 1024px)'),
      wrapper_height:     0,
      init_timeout:       0
    }
  },
  beforeDestroy() {
    clearTimeout(this.init_timeout);
    this.media_breakpoint.removeListener(this.handle_breakpoint_change);
  },
  mounted() {

    this.media_breakpoint.addListener(this.handle_breakpoint_change);

    this.$nextTick(()=> {

      this.init_timeout = setTimeout(()=> {
        this.determine_offset();
        this.set_wrapper_height();
      }, 1000);

    });

  },
  methods: {
    link_href(label) {
      if (!label) return
      label = label.toLowerCase();
      label = label.replace(/ /g, '_');
      return `#${label}`;
    },
    handle_click(index) {

      this.determine_offset(index);

      this.$smoothScroll({
        scrollTo: document.getElementById(
          event.target.hash.replace('#', '')
        ),
        duration: 1000,
        offset: this.vdata.ui_state.subnav_offset
      });

    },
    handle_breakpoint_change() {
      this.determine_offset();
      this.set_wrapper_height();
    },
    handle_intersect(IntersectionObserverEntry) {
      if (IntersectionObserverEntry[0].boundingClientRect.top < 1) {
        this.is_sticky = true;
      } else {
        this.is_sticky = false
      }
    },
    set_wrapper_height() {
      this.wrapper_height = `${this.$refs.subnav.clientHeight}px`;
    },
    toggle_content() {
      if ($(window).width() >= 1024) return;
      this.content_is_visible = !this.content_is_visible;
    },
    determine_offset(index) {
      let offset;
      let subnav_height = -this.$refs.subnav.clientHeight;

      if (this.$refs.subnav.classList.contains('deal__subnav--is-sticky')) {

        if ($(window).width() >= 1024 ) {
          offset = subnav_height;
        } else {

          if (index > this.link_index) {
            offset = subnav_height;
          } else {
            offset = subnav_height * 2 - 30;
          }

          this.link_index = index;

        }
      } else {
        offset = subnav_height;
      }

      this.vdata.ui_state.subnav_offset = offset;

    }
  }
};
</script>
