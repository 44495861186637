import { format, formatISO } from "date-fns";
import { parseISO } from "date-fns";
import { parse } from "date-fns";

const convert_to_utc = function (date) {
    let date_iso = date;

    if (typeof date_iso === "object") {
      date_iso = date.toISOString();
    }

    if (date_iso.includes("Z")) {
      // Create a Date Object using ISO date param string
      const date_obj = new Date(date);

      // Get UTC Values from year, month, day... etc
      const year = date_obj.getUTCFullYear();
      const month = date_obj.getUTCMonth();
      const day = date_obj.getUTCDate();
      const hour = date_obj.getUTCHours();
      const minutes = date_obj.getUTCMinutes();
      const seconds = date_obj.getUTCSeconds();

      // create a ISO date from UTC date
      const date_utc = formatISO(
        new Date(year, month, day, hour, minutes, seconds)
      );
      return parseISO(date_utc);
    } else {
      return parseISO(date_iso);
    }
  },

  currency = function(value, precision = 2){
    // check that value is numeric, if not, set to zero
    if (isNaN(value)) {
      value = 0;
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
    return formatter.format(value);
  },

  empty_date = function (date) {
    if (date === "" || date === null) {
      return "-";
    } else {
      return false;
    }
  },
  boolean_value = function (value) {
    if (value) {
      return "Yes";
    } else {
      return "No";
    }
  },
  ac_short_date = function (date) {
    return empty_date(date) || format(convert_to_utc(date), "LL/dd/yy");
  },
  ac_medium_date = function (date) {
    return empty_date(date) || format(convert_to_utc(date), "MMM dd, yyyy");
  },
  na_if_empty = function (text) {
    if (typeof text === "string") {
      text = $.trim(text);
    }
    text = text === "" ? null : text;
    return text ? text : "n/a";
  },
  number_format = function (number) {
    return new Intl.NumberFormat().format(number);
  },
  sql_date_format = function (date) {
    if (date == "" || date == null) {
      return "-";
    }
    return format(parse(date, "yyyy-MM-dd", new Date()), "MMM dd, yyyy");
  },
  titleize = function (text) {
    var titleized = text.replace(/_/g, " ").replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
    return titleized;
  };

export {
  ac_short_date,
  ac_medium_date,
  boolean_value,
  convert_to_utc,
  currency,
  empty_date,
  na_if_empty,
  number_format,
  sql_date_format,
  titleize,
};
