<template>
  <div class="background-white" data-cy="tabular-data">
    <Subheader :heading="vdata.title_override ? vdata.title_override : vdata.view">
      <label
        class="toggle-button"
        :class="{ 'toggle-button--active': vdata.ui_state.show_filters }"
        data-cy="filters__show-filters-button"
        @click.prevent="toggle_filters"
      >
        <span class="toggle-button__indicator" />
        <span class="toggle-button__text">{{ vdata.ui_state.show_filters ? 'Hide' : 'Show' }} Filters</span>
      </label>
    </Subheader>
    <div class="row collapse">
      <div class="column small-12">
        <FilterPanel :vdata="vdata" />
      </div>
    </div>
    <div class="row align-middle">
      <div class="column shrink">
        <h5 class="body__heading">
          <span v-if="vdata.ui_state.current_saved_view">{{ vdata.ui_state.current_saved_view.name | capitalize }}: </span>
          <span>{{ result_range }}{{ vdata.result.total }} results</span>
        </h5>
      </div>
      <div class="column">
        <div class="loader" :class="{ 'loader--visible': vdata.controller.fetching }" />
      </div>
      <div class="column shrink text-right">
        <div class="table__actions table__actions--extra-spacing">
          <button
            v-if="!vdata.ui_state.current_saved_view"
            type="button"
            class="button button--secondary button--small"
            @click="toggle_create_modal"
          >
            Save To My Views
          </button>

          <button
            v-if="vdata.ui_state.current_saved_view"
            type="button"
            class="button button--small"
            @click="update_current_view"
          >
            Update {{ vdata.ui_state.current_saved_view.name | capitalize }}
          </button>

          <button class="button button--secondary button--small" data-href="/admin/keyboard_shortcuts" data-ajax-modal>
            <span class="button__icon icon-keyboard" />
            Keyboard Shortcuts
          </button>
          <button class="button button--secondary button--small" @click="toggle_modal('saved_views')">
            <span class="button__icon icon-heart" />
            My Views
          </button>
          <button class="button button--secondary button--small" @click="toggle_modal('change_view')">
            <span class="button__icon icon-list" />
            View Settings
          </button>
          <button v-if="!(vdata.view == 'email_templates')" class="button button--secondary button--small" @click="request_csv">
            <span class="button__icon icon-download" />
            Download CSV
          </button>
        </div>
      </div>
    </div>
    <div class="row collapse">
      <div class="column small-12">
        <div class="table__wrapper">
          <table class="table">
            <THead :vdata="vdata" />

            <TBody :vdata="vdata" />
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <Pagination :vdata="vdata" />

        <SavedViewsModal
          :show="vdata.ui_state.show_saved_views_modal"
          :active_columns="vdata.ui_state.active_columns"
          :current_saved_view.sync="vdata.ui_state.current_saved_view"
          :params="vdata.ui_state.params"
          :view="vdata.view"
          :load_view="vdata.controller.load_view"
          @toggle_modal="toggle_modal('saved_views')"
        />

        <ChangeViewModal
          :show="vdata.ui_state.show_change_view_modal"
          :active_columns.sync="vdata.ui_state.active_columns"
          :columns.sync="vdata.ui_state.columns"
          :column_defs="vdata.controller.colDefs"
          @toggle_modal="toggle_modal('change_view')"
        />

        <TextSnippetEditModal
          :vdata="vdata"
          @close="vdata.controller.text_snippet_close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { default as TextSnippetEditModal } from './components/tabular-data/modal-text-snippet-edit.vue';
import { default as ChangeViewModal } from './components/tabular-data/modal-change-view.vue';
import { default as FilterPanel }     from './components/tabular-data/filter-panel.vue';
import { default as Pagination }      from './components/tabular-data/pagination.vue';
import { default as SavedViewsModal } from './components/tabular-data/modal-saved-views.vue';
import { default as Subheader }       from './components/subheader.vue';
import { default as TBody }           from './components/tabular-data/tabular-t-body.vue';
import { default as THead }           from './components/tabular-data/t-head.vue';
import EventBus                       from 'shared/event-bus';

export default
  ({
    name: "TabularData",
    components: {
      TextSnippetEditModal,
      ChangeViewModal,
      FilterPanel,
      Pagination,
      SavedViewsModal,
      Subheader,
      TBody,
      THead
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    computed: {
      result_range(){
        if(this.vdata.result.total > 0 && this.vdata.ui_state.params.pp < this.vdata.result.total){
          const pp = parseInt(this.vdata.ui_state.params.pp);
          const page = parseInt(this.vdata.ui_state.params.page);
          const min = (pp * (page - 1)) + 1;
          const max = Math.min(this.vdata.result.total, ((pp * (page - 1)) + pp));

          return(`Showing ${min}-${max} of `);
        }else{
          return("");
        }
      }
    },
    methods: {
      request_csv(){
        EventBus.$emit('tabular_data:request_csv');
      },
      toggle_create_modal() {
        EventBus.$emit('tabular_data:toggle_saved_views_modal');
        setTimeout( ()=> EventBus.$emit('tabular_data:toggle_create_view'));
      },
      toggle_filters() {
        EventBus.$emit('tabular_data:toggle_filters');
      },
      toggle_modal(modal) {
        EventBus.$emit(`tabular_data:toggle_${modal}_modal`);
      },
      update_current_view() {
        EventBus.$emit('tabular_data:update_saved_views');
      }
    },
  });
</script>
