<template>
  <Modal :show="show" size="large" @close="toggle_modal">
    <template #header>
      <div class="row collapse">
        <div class="small-7 column">
          <div class="modal__content modal__content--header">
            <h5 class="modal__heading">
              Change Table View
            </h5>
            <p>Drag to arrange columns</p>
          </div>
        </div>
        <div class="small-5 column">
          <div class="modal__content modal__content--header text-left">
            <p>Add or Remove Columns</p>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="row collapse">
        <div class="small-7 column">
          <div class="modal__content modal__content--body modal__content--bordered modal__content--radius">
            <Draggable :list="active_table_columns" :move="move_column">
              <div v-for="column in active_table_columns" :key="column" class="modal__draggable-item">
                {{ parse_column_name(column) }}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="bars"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="modal__draggable-icon svg-inline--fa fa-bars fa-w-14"
                ><path fill="#BDBDBD" d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z" class="" /></svg>
              </div>
            </Draggable>
          </div>
        </div>
        <div class="small-5 column">
          <div class="modal__content modal__content--body modal__content--collapse">
            <ul class="menu vertical">
              <li
                v-for="column in table_columns"
                :key="column"
              >
                <label class="input-label">
                  <input
                    v-model="active_table_columns"
                    type="checkbox"
                    :value="column"
                    @click="toggle_column(column)"
                  > {{ parse_column_name(column) }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { default as Draggable } from 'vuedraggable/src/vuedraggable.js';
import { default as Modal } from 'shared/modal.vue';

export default {
  name: "ChangeViewModal",
  components: {
    Draggable,
    Modal
  },
  props: {
    active_columns: {
      type:     Array,
      required: true
    },
    columns: {
      type:     Array,
      required: true
    },
    column_defs: {
      type:     Object,
      required: true
    },
    show: {
      type:     Boolean,
      required: true
    }
  },
  data: function() {
    return {
      table_columns:        this.columns,
      active_table_columns: this.active_columns
    }
  },
  methods: {
    move_column(event){
      const index = this.table_columns.findIndex(value => value === event.draggedContext.element);
      this.table_columns.splice(index, 1);
      this.table_columns.splice(event.draggedContext.futureIndex, 0, event.draggedContext.element);
      this.$emit('update:columns', this.table_columns);
    },
    toggle_column(column){
      if (this.active_table_columns.includes(column)) {
        this.active_table_columns = this.active_table_columns.filter(value => value !== column);
      } else {
        const index = this.table_columns.findIndex(value => value === column);
        this.active_table_columns.splice(index, 0, column);
      }
      this.$emit('update:active_columns', this.active_table_columns);
    },
    parse_column_name(name){
      return this.column_defs[name].label;
    },
    toggle_modal() {
      this.$emit('toggle_modal');
    },
  }
};
</script>

<style lang="scss" scoped>
  .modal {

    &__content {
      margin-left: 90px;
      margin-right: 90px;

      &--body {
        height: 350px;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      &--collapse {
        margin-left: 0;
        margin-right: 0;
      }

      &--header {
        padding-top: 100px;
        margin: 0;
      }
    }

    &__draggable-item {
      background: #fff;
      border: 1px solid #E0E0E0;
      color: #4F4F4F;
      cursor: move;
      margin: 0 0 9px;
      padding: 5px 12px;
      position: relative;
      text-transform: capitalize;
    }

    &__draggable-icon {
      bottom: 0;
      height: 20px;
      margin: auto;
      position: absolute;
      right: 10px;
      top: 0;
      width: 20px;
    }
  }
</style>
