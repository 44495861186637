<template>
  <div class="deal__module">
    <slot />
    <div v-html="module.content" />
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */

export default {
  name: "VideoEmbed",
  props: {
    module: {
      type:     Object,
      required: true
    }
  }
};
</script>
