<template>
  <a @click="open_preview">{{ row.email_to }}</a>
</template>

<script>
import { EventBus } from 'shared/vue-configured';

export default {
  name: 'EmailPreviewOpener',
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    open_preview(){
      EventBus.$emit('email-preview-modal:open_preview', this.row.id);
    }
  }
};
</script>
