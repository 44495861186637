<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <div class="row collapse">
      <div class="column medium-6">
        <label class="input-label">{{ label }} From </label>
        <Datepicker
          v-model="model[model_keys[0]]"
          class="input input--radius-left"
          placeholder="From Date"
          data-cy="filters__date-range-from"
          :data-cy-key="model_keys[0]"
        />
      </div>
      <div class="column medium-6">
        <label class="input-label">{{ label }} To </label>
        <Datepicker
          v-model="model[model_keys[1]]"
          class="input input--radius-right"
          placeholder="To Date"
          data-cy="filters__date-range-to"
          :data-cy-key="model_keys[1]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'admin/components/date-picker.vue';


/*
 *
 * The Date range component generates two date inputs
 *
 */
export default {
  name: 'DateRange',
  components: {
    Datepicker
  },
  props: {
    label: {
      type: String,
      required: true
    },
    model_keys: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {typeable: false};
  },
  mounted() {
    // Date range inputs need to be typeable for Cypress integration tests
    // @see {spec/cypress/support/commands/list-filters.js}
    if (typeof window.Cypress !== 'undefined') {
      this.typeable = true;
    }
  }
};
</script>
