<template>
  <div class="column small-12 medium-6 large-4 xlarge-3">
    <label class="input-label" for="search">{{ label }}</label>
    <input
      v-model="model[model_keys[0]]"
      class="input"
      type="search"
      placeholder="Keywords"
      data-cy="filters__text-search-field"
      :data-cy-key="model_keys[0]"
    >
  </div>
</template>

<script>
export default {
  name: 'TextSearchField',
  props: {
    label: {
      type: String,
      required: true
    },
    model_keys: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  }
};
</script>
