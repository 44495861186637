<template>
  <div class="table__sorter">
    <div
      class="table__sorter-icon table__sorter-icon--up"
      :class="{ 'table__sorter-icon--active' : up_active}"
      @click="set_sort(column)"
    />
    <div
      class="table__sorter-icon table__sorter-icon--down"
      :class="{ 'table__sorter-icon--active' : down_active}"
      @click="set_sort(column)"
    />
  </div>
</template>

<script>
export default {
  name: 'Sorter',
  props: {
    set_sort: {
      type: Function,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    order: {
      type: String,
      required: true
    },
    orderBy: {
      type: String,
      required: true
    }
  },
  computed: {
    up_active() {
      return(this.orderBy === this.column && this.order === "asc");
    },
    down_active() {
      return(this.orderBy === this.column && this.order === "desc");
    }
  }
};
</script>
